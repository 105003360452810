import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { SharedService } from '../shared.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { sortBy } from 'lodash';
import { Article } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

export interface LotNumber {
	code: string;
	availableQuantity: number;
	expirationDate: string;
	selectedQuantity: number;
}

@Component({
    selector: 'ft-article-lots',
    templateUrl: './article-lots.component.html',
    styleUrls: ['./article-lots.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatPrefix,
        MatInput,
        FormsModule,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ArticleLotsComponent implements AfterViewInit {
	lotsSource: LotNumber[] = [];
	lots: LotNumber[] = [];
	@ViewChild('search', { static: true }) filter: ElementRef;
	selection = new SelectionModel<LotNumber>(true, []);

	neededQuantity: number = 1;
	selectedQuantity: number = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA) public article: Article,
		private shared: SharedService,
		private dialogRef: MatDialogRef<ArticleLotsComponent>
	) {
		this.neededQuantity = this.article.quantity;
		this.shared
			.getArticleLots(this.article.externalId, this.article.store)
			.subscribe(
				res =>
					(this.lots = this.lotsSource =
						sortBy(
							res.map(it => it.split('@')),
							'[2]'
						).map(it => {
							return {
								code: it[0],
								availableQuantity: parseFloat(it[1]),
								expirationDate: this.formatDate(it[2]),
								selectedQuantity: 0,
							};
						}))
			);
	}

	ngAfterViewInit(): void {
		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400))
			.subscribe(ev => this.filterTable(ev));

		setTimeout(() => this.alreadySelected(this.article), 500);
	}

	clearInput() {
		this.filter.nativeElement.value = '';
		this.lots = this.lotsSource;
	}

	validateSelection() {
		const selectedLots = this.selection.selected;
		this.dialogRef.close(selectedLots);
	}

	private filterTable(ev: any) {
		this.lots = this.lotsSource.filter(it =>
			it.toString().includes(ev.target.value)
		);
	}

	formatDate(dateString: string): string {
		return moment(dateString, 'YYYYMMDD HH:mm').format('DD/MM/YYYY HH:mm');
	}

	updateLot(lot: LotNumber) {
		const selectedBefore = this.selection.selected
			.map(it => it.selectedQuantity)
			.reduce((p, c) => p + c, 0);

		if (lot.selectedQuantity > lot.availableQuantity)
			lot.selectedQuantity = lot.availableQuantity;
		lot.selectedQuantity > 0
			? this.selection.select(lot)
			: this.selection.deselect(lot);
		this.selectedQuantity = this.selection.selected
			.map(it => it.selectedQuantity)
			.reduce((p, c) => p + c, 0);

		if (this.selectedQuantity > this.neededQuantity) {
			lot.selectedQuantity = this.neededQuantity - selectedBefore;
			lot.selectedQuantity > 0
				? this.selection.select(lot)
				: this.selection.deselect(lot);
			this.selectedQuantity = this.selection.selected
				.map(it => it.selectedQuantity)
				.reduce((p, c) => p + c, 0);
		}
	}

	private alreadySelected(article: Article) {
		if (article.lots && this.lotsSource) {
			const articleLots: string[] = article.lots.split(',');

			const mapLots = articleLots.map(it => {
				const sp = it.split('_');
				return {
					code: sp[0],
					selectedQuantity: parseFloat(sp[1]),
				};
			});

			this.lotsSource.forEach(lot => {
				const selectedLot = mapLots.find(lt => lt.code === lot.code);
				if (selectedLot) {
					lot.selectedQuantity = selectedLot['selectedQuantity'] || 0;
					this.updateLot(lot);
				}
			});
		}
	}
}
