import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slice',
    standalone: true,
})
export class SlicePipe implements PipeTransform {
	transform(array: any[], args: any[]): any[] {
		const [start, end] = args;
		const l = array?.length;
		return l < end ? array?.slice(start, l) : array?.slice(start, end);
	}
}
