<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-account-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "ADD_REF_PHYSICIAN" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div class="fx-padding-24" [formGroup]="referringForm">
  <div class="fx-layout-row-nowrap fx-gap-4">
    <mat-form-field floatLabel="auto">
      <mat-label>{{ "TITLE" | translate }}</mat-label>
      <mat-select
        [placeholder]="'TITLE' | translate"
        formControlName="titleId"
        tabindex="-1"
      >
        @for (title of titles; track $index) {
        <mat-option [value]="title.id">{{ title.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "LAST_NAME" | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'LAST_NAME' | translate"
        formControlName="lastName"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "FIRST_NAME" | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'FIRST_NAME' | translate"
        formControlName="firstName"
        required
      />
    </mat-form-field>
  </div>

  <div class="fx-layout-row-nowrap fx-gap-4">
    <mat-form-field>
      <mat-label>{{ "SPECIALITY" | translate }}</mat-label>
      <mat-select
        [placeholder]="'SPECIALITY' | translate"
        formControlName="specialtyId"
      >
        @for (speciality of specialities; track $index) {
        <mat-option [value]="speciality.id">{{ speciality.value }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "PHONE" | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'PHONE' | translate"
        formControlName="phone"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "CODE_PRO" | translate }}</mat-label>
      <input
        [placeholder]="'CODE_PRO' | translate"
        formControlName="codeAnam"
        matInput
      />
    </mat-form-field>
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "EMAIL" | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'EMAIL' | translate"
        formControlName="email"
      />
    </mat-form-field>
  </div>

  <mat-form-field class="fx-grow-1 fx-fill-width">
    <mat-label>{{ "ADDRESS" | translate }}</mat-label>
    <textarea
      matInput
      [placeholder]="'ADDRESS' | translate"
      formControlName="primaryAddress"
    ></textarea>
  </mat-form-field>

  <h4>{{ "SECONDARY_ADDRESSES" | translate }}</h4>

  @for ( _ of referringForm.get('secondaryAddresses')['controls']; let i =
  $index; track $index ) {
  <div
    formArrayName="secondaryAddresses"
    class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4"
    style="margin-bottom: 8px"
  >
    <ng-container [formGroupName]="i">
      <mat-form-field subscriptSizing="dynamic" style="width: 100px">
        <mat-label>{{ "LABEL" | translate }}</mat-label>
        <input
          [placeholder]="'LABEL' | translate"
          formControlName="label"
          matInput
        />
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic" class="fx-grow-1">
        <mat-label>{{ "ADDRESS" | translate }}</mat-label>
        <input
          [placeholder]="'ADDRESS' | translate"
          formControlName="value"
          matInput
        />
      </mat-form-field>

      @if ( i === referringForm.get('secondaryAddresses')['controls'].length - 1
      ) {
      <button mat-icon-button color="primary" (click)="addAddress()">
        <mat-icon fontIcon="mdi-plus-circle" fontSet="mdi"></mat-icon>
      </button>
      }

      <button mat-icon-button color="warn" (click)="removeAddress(i)">
        <mat-icon fontIcon="mdi-minus-circle" fontSet="mdi"></mat-icon>
      </button>
    </ng-container>
  </div>
  }
</div>

<div matDialogActions align="end">
  <button color="warn" matDialogClose="" mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
  <button
    (click)="save(referringForm.value)"
    [disabled]="referringForm.invalid"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
