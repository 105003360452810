import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingService } from '../../setting/setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-new-template',
    templateUrl: './new-template.component.html',
    styleUrls: ['./new-template.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatSlideToggle,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class NewTemplateComponent implements OnInit {
	categories: any[] = [];

	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private _service: SettingService,
		private _snack: MatSnackBar,
		private _dialogRef: MatDialogRef<NewTemplateComponent>
	) {
		this.form = this._fb.group({
			name: ['', Validators.required],
			reportModel: '',
			category: ['', Validators.required],
			listType: 'STD',
			isPrivate: false,
			owner: '',
		});
	}

	ngOnInit(): void {
		this._service
			.getTemplateModelCategories()
			.subscribe(data => (this.categories = data));
	}

	saveReportTemplate(value: any) {
		this._service
			.createTemplateModelFromReport(value, this.data)
			.subscribe(res => {
				if (res) {
					this._dialogRef.close();
					this._snack.open('Report template saved!', '', {
						duration: 2000,
					});
				}
			});
	}
}
