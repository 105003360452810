<mat-toolbar color="primary">
  <h3>{{ "NORMAL_REPORT" | translate }}</h3>
  <span class="fx-grow-1"></span>
  <span
    >{{ reportingTask?.patientName }} -
    {{ reportingTask?.procedureCodes }}</span
  >
  <span class="fx-grow-1"></span>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content
  style="padding: 4px !important; min-height: calc(100vh - 92px)"
>
  <div class="fx-layout-row-nowrap fx-content-space-between fx-gap-4">
    @if (templateModels) {
    <div style="width: 300px">
      <mat-nav-list dense class="my-list">
        @for (tm of templateModels; track $index) {
        <mat-list-item
          (click)="showTemplateModel(tm)"
          style="height: 27px !important"
        >
          {{ tm.name }}
        </mat-list-item>
        }
      </mat-nav-list>
    </div>
    }
    <div class="fx-grow-1"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    [matMenuTriggerFor]="printersMenu"
    color="primary"
    [matTooltip]="'PRINT' | translate"
  >
    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
  </button>
  <mat-menu #printersMenu="matMenu">
    @for (printer of printers; track $index) {
    <button mat-menu-item (click)="saveAndPrint(printer['label'])">
      <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
      {{ printer["label"] }}
    </button>
    }
  </mat-menu>
  <span class="fx-grow-1"></span>
  <button
    mat-raised-button
    color="warn"
    [matTooltip]="'CLOSE' | translate"
    mat-dialog-close
  >
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    [matTooltip]="'SAVE_AND_EXIT' | translate"
    (click)="saveAndExit()"
  >
    <mat-icon fontSet="mdi" fontIcon="mdi-floppy"></mat-icon>
  </button>
</mat-dialog-actions>
