<mat-form-field [formGroup]="group">
  <mat-label>{{ config.placeholder }}</mat-label>
  <mat-select placeholder="{{ config.name }}" [formControlName]="config.name">
    @for (option of config.options; track $index) {
    <mat-option [value]="option.value">
      {{ option.value }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
