<mat-toolbar class="ftx-dialog-toolbar">
  <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "PACS_DATA" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div class="fx-layout-column fx-content-start">
  <div
    class="fx-search-area fx-layout-row fx-content-start fx-items-end fx-padding-8 fx-gap-4"
    [formGroup]="queryForm"
  >
    <mat-form-field subscriptSizing="dynamic">
      <mat-icon matIconPrefix fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
      <input
        matInput
        placeholder="{{ 'PATIENT_NAME' | translate }}"
        formControlName="patientName"
      />
    </mat-form-field>

    <span class="fx-grow-1"></span>

    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      name="period"
      aria-label="Period"
      (change)="changeRange($event)"
    >
      <mat-button-toggle value="TODAY">
        {{ "TODAY" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="3D">
        {{ "THREE_DAYS" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="1W">
        {{ "ONE_WEEK" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="2W">
        {{ "TWO_WEEKS" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="1M">
        {{ "ONE_MONTH" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="3M">
        {{ "THREE_MONTHS" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="OT">
        {{ "ALL" | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  @if (dataSource.data.length > 0) {
  <mat-table
    [dataSource]="dataSource"
    style="height: calc(100vh - 204px)"
    class="fx-grow-1 fx-overflow-auto"
    [trackBy]="trackById"
    matSort
    matSortActive="studyDate"
    matSortDisableClear
    matSortDirection="desc"
  >
    <!--Table-->
    @for (col of displayedColumns; track $index) { @if (col === 'studyDate') {
    <ng-container matColumnDef="{{ col }}">
      <mat-header-cell *matHeaderCellDef>
        {{ col | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ formatDate(row[col]) }}
      </mat-cell>
    </ng-container>
    } @if (col === 'studyTime') {
    <ng-container matColumnDef="{{ col }}">
      <mat-header-cell *matHeaderCellDef>
        {{ col | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ formatTime(row[col]) }}
      </mat-cell>
    </ng-container>
    } @if (!['action', 'studyDate', 'studyTime'].includes(col)) {
    <ng-container matColumnDef="{{ col }}">
      <mat-header-cell *matHeaderCellDef>
        {{ col | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ formatString(row[col]) }}
      </mat-cell>
    </ng-container>
    } }

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          class="fx-layout-row-nowrap fx-content-end fx-items-center"
          style="width: 100%"
        >
          <button
            (click)="createPatientExam(row)"
            [matTooltip]="'CREATE_EXAM' | translate"
            mat-icon-button
          >
            <mat-icon
              fontIcon="mdi-database-plus"
              fontSet="mdi"
              style="color: #2196f3"
            ></mat-icon>
          </button>
          @if (mergeEnabled) {
          <button
            (click)="mergeExam(row)"
            [matTooltip]="'MERGE_EXAM' | translate"
            mat-icon-button
          >
            <mat-icon
              fontIcon="mdi-source-merge"
              fontSet="mdi"
              style="color: #e91e63"
            ></mat-icon>
          </button>
          } @if (defaultViewer) {
          <button
            (click)="openInViewer(row)"
            [matTooltip]="'OPEN_IN_VIEWER' | translate"
            mat-icon-button
          >
            <mat-icon
              fontIcon="mdi-monitor"
              fontSet="mdi"
              style="color: #0a666f"
            ></mat-icon>
          </button>
          }
        </div>
      </mat-cell>
    </ng-container>

    <!--header-->
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      [@rowsAnimation]=""
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
  }

  <div
    class="fx-table-empty"
    [style.display]="dataSource.data.length === 0 ? '' : 'none'"
    style="height: calc(100vh - 204px); width: 80vw"
  >
    @if (isLoadingResults) {
    <div>
      <mat-spinner color="warn" [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>
    } @else {
    {{ "NOITEMSFOUND" | translate }}
    }
  </div>

  <mat-paginator
    style="border-block: 1px solid lightgrey"
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 20, 50, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>

<div matDialogActions align="end" class="p-3">
  <button color="warn" mat-raised-button matDialogClose="null">
    {{ "CLOSE" | translate }}
  </button>
</div>
