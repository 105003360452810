<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-folder"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "SMS_SENDER" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<div class="fx-layout-column-nowrap fx-padding-24">
  <mat-form-field>
    <mat-label>{{ "DESTINATION" | translate }}</mat-label>
    <input
      [formControl]="destinationControl"
      [placeholder]="'DESTINATION' | translate"
      aria-describedby="Destination"
      matInput
      tabindex="-1"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "SMS_TEMPLATE" | translate }}</mat-label>
    <mat-select
      (selectionChange)="onSelectTemplate($event)"
      [formControl]="templateControl"
      tabindex="-1"
    >
      @for (template of smsTemplates; track $index) {
      <mat-option [value]="template.title">{{ template.title }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "MESSAGE" | translate }}</mat-label>
    <textarea
      [formControl]="messageControl"
      [placeholder]="'MESSAGE' | translate"
      [rows]="40"
      matInput
      style="height: 100px"
    ></textarea>
  </mat-form-field>
</div>
@if (messageError) {
<div style="text-align: center; color: red; font-style: italic">
  {{ messageError }}
</div>
}
<div align="end" matDialogActions>
  <button
    (click)="sendSms()"
    [disabled]="destinationControl.invalid || messageControl.invalid"
    color="primary"
    mat-button
  >
    {{ "SEND" | translate }}
  </button>
  <button color="warn" mat-button matDialogClose="null">
    {{ "CANCEL" | translate }}
  </button>
</div>
