import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Report, ReportingTask, TemplateModelDTO } from '../../model';
import { SettingService } from '../../setting/setting.service';
import { assign } from 'lodash';
import { FormControl } from '@angular/forms';
import { savePatientDataLocally } from '../shared-functions';
import { ReportingService } from '../../reporting/reporting.service';
import { SharedService } from '../shared.service';
import { PrintCountComponent } from '../print-count/print-count.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatNavList, MatListItem } from '@angular/material/list';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-normal-report',
    templateUrl: './normal-report.component.html',
    styleUrls: ['./normal-report.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        MatNavList,
        MatListItem,
        MatDialogActions,
        MatButton,
        MatMenuTrigger,
        MatTooltip,
        MatMenu,
        MatMenuItem,
        TranslateModule,
    ],
})
export class NormalReportComponent implements OnInit {
	static count = 0;

	editor: any;
	options: string[] = ['One', 'Two', 'Three'];
	patient: any;
	procedure: any;
	templateModels: TemplateModelDTO[] = [];
	selectedModel: TemplateModelDTO;
	selectedReport: Report;
	reportingTask: ReportingTask;
	taskType: string;
	pathologyControl = new FormControl();
	studyInstanceUID: string;
	profile: any;
	group = 'template';
	patientID: string;
	printers: any[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: number,
		private service: ReportingService,
		private snackBar: MatSnackBar,
		private setting: SettingService,
		private translate: TranslateService,
		private shared: SharedService,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<NormalReportComponent>
	) {}

	public selectReport = (report: Report) => {
		if (report) {
			this.selectedReport = report;
			this.selectedModel = report.templateModel;
		}
	};

	private getPatientInfos = (patientID: string) =>
		this.service.getPatientInfo(patientID).subscribe(patientInfo => {
			savePatientDataLocally(patientInfo, this.translate);

			this.patient = assign({
				patientID: patientInfo.patientID,
				patientName: patientInfo.fullName,
				patientAge: patientInfo.age,
				patientSex: patientInfo.sex,
				alerts: patientInfo.alerts,
			});
		});

	public createReport(event) {
		event.stopPropagation();
		event.stopImmediatePropagation();

		const newReport: Report = {
			id: null,
			name: 'Rapport_' + NormalReportComponent.count++,
			lastModified: new Date(),
			reportData: [],
			dictations: [],
			templateModel: this.templateModels[0],
		};

		this.reportingTask.report = newReport;

		this.selectReport(newReport);
	}

	showTemplateModel(tm: TemplateModelDTO) {
		console.log(tm.name);

		this.selectedReport.templateModel = tm;
		this.selectedReport.name = tm.name;
		this.selectedModel = tm;

		this.selectReport(this.selectedReport);

		this.shared.changeTemplate(tm);
	}

	close() {
		this.dialogRef.close(null);
	}

	saveAndPrint(printer: any) {
		this.printReport(printer);
	}

	saveAndExit() {
		this.saveReport();
		this.close();
	}

	public printReport(printer: any) {
		this.dialog
			.open(PrintCountComponent, { disableClose: true })
			.afterClosed()
			.subscribe(res => {
				if (res != 0) {
					// check if the selected report already persisted in db, else
					// reportData key values should be with null ids to prevent merge data for multiple reports
					if (this.selectedReport.id === null) {
						this.selectedReport.reportData =
							this.selectedReport.reportData.map(kv => {
								return { key: kv.key, value: kv.value };
							});
					}

					this.service
						.saveReportingTask(this.reportingTask)
						.subscribe(reportingTask => {
							this.initViewData(reportingTask);
							this.service
								.printSimpleReport(
									reportingTask.id,
									printer,
									res
								)
								.subscribe();
						});
				}
			});
	}

	ngOnInit() {
		this.setting
			.getNormalTemplateModels()
			.subscribe(value => (this.templateModels = value));
		this.service
			.getReportingTask(+this.data)
			.subscribe(reportingTask => this.initViewData(reportingTask));
		this.shared.getPrinters().subscribe(data => (this.printers = data));
	}

	public saveReport() {
		// check if the selected report already persisted in db, else
		// reportData key values should be with null ids to prevent merge data for multiple reports
		if (this.selectedReport.id === null) {
			this.selectedReport.reportData = this.selectedReport.reportData.map(
				kv => {
					return { key: kv.key, value: kv.value };
				}
			);
		}

		this.service
			.saveReportingTask(this.reportingTask)
			.subscribe(reportingTask => {
				this.snackBar.open(
					'Compte rendu enregistré avec succès',
					'Ok',
					{ duration: 2000 }
				);
				this.initViewData(reportingTask);
			});
	}

	private initViewData(reportingTask: ReportingTask): void {
		this.reportingTask = reportingTask;
		this.taskType = reportingTask.reportingTaskType.value;

		const pathology = this.reportingTask.pathology;
		if (pathology) {
			this.pathologyControl.patchValue(
				this.reportingTask.pathology.split(',')
			);
		} else this.pathologyControl.patchValue('');

		this.studyInstanceUID = reportingTask.studyInstanceUID;
		this.patientID = reportingTask.patientID;

		this.selectedReport = reportingTask.report;

		const templateModel = this.selectedReport.templateModel;

		this.selectReport(this.selectedReport);

		this.service.getSPSByInstances(this.studyInstanceUID).subscribe(sps => {
			if (sps) {
				this.getPatientInfos(sps.patientID);

				this.selectedModel =
					templateModel || sps.procedureCode.templateModel;
				this.selectedReport.templateModel = this.selectedModel;

				this.selectReport(this.selectedReport);
			}
		});
	}
}
