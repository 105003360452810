import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'localDate', pure: true,
    standalone: true
})
export class LocalDatePipe implements PipeTransform {
	transform(value: any, format?: any): string | null {
		let date: Date;

		if (value.length === 6) {
			const [y, m, d, h, mn, s] = value;
			date = new Date(y, m - 1, d, h, mn, s);
		}

		if (value.length === 3) {
			const [y, m, d] = value;
			date = new Date(y, m - 1, d);
		}

		return moment(date).format(format ? format : 'DD/MM/YYYY');
	}
}
