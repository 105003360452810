import { Component, Inject, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { assign } from 'lodash';
import { forkJoin } from 'rxjs';
import { PathologyEditComponent } from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-patient-arrived',
    templateUrl: './patient-arrived.component.html',
    styleUrls: ['./patient-arrived.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatSuffix,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class PatientArrivedComponent implements OnInit {
	sideEffectsList: any[];
	radiologists: any[];
	assistantRadiologists: any[] = [];

	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private shared: SharedService,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<PatientArrivedComponent>
	) {
		this.form = this.fb.group({
			patientID: '',
			patientName: '',
			reasonForExam: '',
			procedureCode: '',
			performingPhysician: '',
			assistantPhysicians: '',
			sideEffects: '',
			observation: '',
			clinicalInfos: '',
		});

		this.form.get('performingPhysician').valueChanges.subscribe(value => {
			setTimeout(
				() =>
					(this.assistantRadiologists =
						this.assistantRadiologists.filter(
							val => val.id !== value
						)),
				200
			);
		});

		this.form.get('patientID').disable();
		this.form.get('patientName').disable();
		this.form.get('reasonForExam').disable();
		this.form.get('procedureCode').disable();
	}

	onSave(value) {
		value.sideEffects = this.sideEffectsList
			.filter(val => value.sideEffects.indexOf(val.value) !== -1)
			.map(v => v.value);

		const patient = this.data.isr.patient;
		const rp = this.data.isr.requestedProcedure;
		const sps = rp.scheduledProcedureSteps[0];
		const mh = patient.medicalHistory;

		const perfPhys = this.radiologists.filter(
			val => val.id === value.performingPhysician
		)[0];

		const isr = assign(this.data.isr, {
			patient: assign(patient, {
				medicalHistory: assign(mh, {
					additionalPatientHistory: value.clinicalInfos,
					observations: value.observation,
					allergies: value.sideEffects.join(','),
				}),
			}),
			requestedProcedure: assign(rp, {
				scheduledProcedureSteps: [
					assign(sps, {
						scheduledPerformingPhysiciansName: perfPhys,
						assistantPhysicians: value.assistantPhysicians
							? value.assistantPhysicians.join(',')
							: '',
					}),
				],
			}),
		});

		this.dialogRef.close(isr);
	}

	addAllergy(event) {
		event.stopPropagation();
		this.dialog
			.open(PathologyEditComponent, {
				data: { type: 'external', icon: 'plus', title: 'NEW_ALLERGY' },
				disableClose: true,
			})
			.afterClosed()
			.subscribe(data => {
				if (data) {
					this.shared.createAllergy(data).subscribe(res => {
						this.sideEffectsList.push(res);
						const allergies = this.form.get('sideEffects').value;
						allergies.push(res.value);
						this.form.get('sideEffects').patchValue(allergies);
					});
				}
			});
	}

	ngOnInit() {
		forkJoin([
			this.shared.getPerformingPhysicians(),
			this.shared.getAllergies(),
		]).subscribe(data => {
			[this.radiologists, this.sideEffectsList] = data;

			this.assistantRadiologists = data[0];

			const patient = this.data.isr.patient;
			const rp = this.data.isr.requestedProcedure;
			const sps = rp ? rp.scheduledProcedureSteps[0] : null;

			this.form.patchValue({
				patientID: patient.externalPatientID,
				patientName:
					!patient.confidential || this.data.canViewConfData
						? patient.fullName
						: '**** ****',
				reasonForExam: rp ? rp.reasonForRequestedProcedure.value : '-',
				procedureCode: sps.procedureCode.code,
				performingPhysician: sps.scheduledPerformingPhysiciansName
					? sps.scheduledPerformingPhysiciansName.id
					: null,
				clinicalInfos: patient.medicalHistory
					? patient.medicalHistory.additionalPatientHistory
					: '',
				observation: patient.medicalHistory
					? patient.medicalHistory.observations
					: '',
				sideEffects: patient.medicalHistory
					? patient.medicalHistory.allergies.split(',')
					: '',
			});
		});
	}
}
