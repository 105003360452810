<mat-toolbar class="ftx-dialog-toolbar" color="primary">
	<mat-icon fontSet="mdi" [fontIcon]="'mdi-' + headerIcon"></mat-icon>
	<h3 class="text-lg font-semibold pl-1">{{ title | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<form [formGroup]="form" class="fx-fill-width">
		<mat-form-field class="fx-fill-width">
			<mat-label>{{ title | translate }}</mat-label>
			<textarea
				matInput
				placeholder="{{ title | translate }}"
				formControlName="value"
				required></textarea>
		</mat-form-field>
	</form>
</div>
<mat-dialog-actions align="end">
	<button mat-raised-button color="warn" [mat-dialog-close]="null">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="saveData(form.value)"
		[disabled]="form.invalid">
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
