import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import RecordRTC from 'recordrtc';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-record-rtc',
    templateUrl: './record-rtc.component.html',
    styleUrls: ['./record-rtc.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
    ],
})
export class RecordRtcComponent implements OnInit, AfterViewInit {
	@ViewChild('video', { static: true }) video: any;
	private stream: MediaStream;
	private recordRTC: any;

	constructor() {}

	ngOnInit() {
		this.startRecording();
	}

	ngAfterViewInit() {
		// set the initial state of the video
		const video: HTMLVideoElement = this.video.nativeElement;
		video.muted = false;
		video.controls = true;
		video.autoplay = false;
	}

	startRecording() {
		const mediaConstraints = {
			video: {
				width: { min: 640, ideal: 1920, max: 2060 },
				height: { min: 400, ideal: 1080 },
			},
			audio: true,
		};
		navigator.mediaDevices
			.getUserMedia(mediaConstraints)
			.then(
				this.successCallback.bind(this),
				this.errorCallback.bind(this)
			);
	}

	successCallback(stream: MediaStream) {
		const options = {
			mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
			audioBitsPerSecond: 128000,
			videoBitsPerSecond: 128000,
			bitsPerSecond: 128000, // if this line is provided, skip above two
		};
		this.stream = stream;
		this.recordRTC = RecordRTC(stream, options);
		this.recordRTC.startRecording();
		const video: HTMLVideoElement = this.video.nativeElement;
		video.srcObject = stream;
		this.toggleControls();
	}

	errorCallback() {
		//handle error here
	}

	toggleControls() {
		const video: HTMLVideoElement = this.video.nativeElement;
		video.muted = !video.muted;
		video.controls = !video.controls;
		video.autoplay = !video.autoplay;
	}

	stopRecording() {
		const recordRTC = this.recordRTC;
		recordRTC.stopRecording(this.processVideo.bind(this));
		const stream = this.stream;
		stream.getAudioTracks().forEach(track => track.stop());
		stream.getVideoTracks().forEach(track => track.stop());
	}

	processVideo(audioVideoWebMURL) {
		const video: HTMLVideoElement = this.video.nativeElement;
		const recordRTC = this.recordRTC;
		video.src = audioVideoWebMURL;
		this.toggleControls();
		const recordedBlob = recordRTC.getBlob();
		recordRTC.getDataURL(function (dataURL) {});
	}

	download() {
		this.recordRTC.save('video.webm');
	}
}
