<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-alpha-l-box"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "ARTICLE_LOTS" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<div class="fx-padding-0" style="max-height: 60vh; overflow: auto">
  <div
    class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8"
  >
    <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
      <mat-icon matIconPrefix fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
      <input matInput #search placeholder="{{ 'SEARCHING' | translate }}" />
    </mat-form-field>
  </div>

  <div class="limiter">
    <div class="table">
      <div class="row header">
        <div class="cell">
          {{ "CODE" | translate }}
        </div>
        <div class="cell">
          {{ "AVAILABLE_QUANTITY" | translate }}
        </div>
        <div class="cell">
          {{ "EXPIRY_DATE" | translate }}
        </div>
        <div class="cell">{{ "QUANTITY" | translate }}</div>
        <div class="cell"></div>
      </div>

      @for (lot of lots; track $index) {
      <div
        [class.row-selected]="selection.isSelected(lot)"
        [style.cursor]="'pointer'"
        class="row"
      >
        <div class="cell">{{ lot.code }}</div>
        <div class="cell">{{ lot.availableQuantity }}</div>
        <div class="cell">{{ lot.expirationDate }}</div>
        <div class="cell">
          <input
            class="ft-input ft-qty"
            (keyup)="updateLot(lot)"
            [(ngModel)]="lot.selectedQuantity"
            [ngModelOptions]="{ standalone: true }"
            type="number"
          />
        </div>

        <div class="cell">
          <mat-icon
            [fontIcon]="selection.isSelected(lot) ? 'mdi-check' : ''"
            [style.color]="'green'"
            [style.marginRight.px]="8"
            [style.top.px]="0"
            fontSet="mdi"
          ></mat-icon>
        </div>
      </div>
      } @if (!lots.length) {
      <div class="row">
        <div class="no-cell">No lot number found</div>
      </div>
      }
    </div>
  </div>

  <div
    class="fx-layout-row fx-content-end fx-items-center fx-gap-12 fx-fill-width"
  >
    <div
      style="
        text-align: end;
        font-size: 16px;
        font-weight: bold;
        color: #425761;
      "
    >
      {{ "SELECTED_QUANTITY" | translate }}
      :
    </div>
    <div
      [style.color]="selectedQuantity < neededQuantity ? 'red' : 'green'"
      style="width: 60px; font-size: 26px; font-weight: bold"
    >
      {{ selectedQuantity }}/{{ neededQuantity }}
    </div>
  </div>
</div>
<div matDialogActions align="end">
  <button color="warn" mat-raised-button matDialogClose="">
    {{ "EXIT" | translate }}
  </button>
  <button
    (click)="validateSelection()"
    [disabled]="!selection.hasValue()"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
