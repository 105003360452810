@if (tableConfig) {
<div class="formatting-card">
  <div
    class="formatting-card-header fx-layout-row-nowrap fx-content-space-between fx-items-center"
  >
    <span>{{ "FORMATTING_RULE" | translate }}</span>
    <button (click)="closeEvent.emit(true)" mat-icon-button>
      <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </button>
  </div>

  @if (selectedRule) {
  <ft-formatting-rule
    class="style-line-form"
    (applyChangesEvent)="saveChanges($event)"
    (cancelEvent)="selectedRule = null"
    [columns]="tableConfig.tableColumns"
    [rule]="selectedRule"
  ></ft-formatting-rule>
  } @else {
  <div
    (cdkDropListDropped)="drop($event)"
    cdkDropList
    class="style-lines-container"
  >
    @for (rule of getFormattingRules(); track $index) {
    <ft-formatting-style-line
      (click)="editRule(rule)"
      (deleteEvent)="deleteRule(rule)"
      [formattingRule]="rule"
      cdkDrag
    >
    </ft-formatting-style-line>
    }
  </div>
  }
</div>
} @if (!selectedRule) {
<button
  (click)="addNewRule()"
  [matTooltip]="'ADD_ANOTHER_RULE' | translate"
  class="add-new"
  color="warn"
  mat-mini-fab
>
  <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
</button>
}
