import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { RendezVous } from '../../model';
import moment from 'moment';
import { assign } from 'lodash';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-rdv-print',
    templateUrl: './rdv-print.component.html',
    styleUrls: ['./rdv-print.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class RdvPrintComponent {
	rdvForm: FormGroup;
	time: any = '10:00';
	public minDate = new Date();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private service: SchedulerService,
		private fb: FormBuilder,
		private _dialogRef: MatDialogRef<RdvPrintComponent>
	) {
		this.rdvForm = this.fb.group(new RendezVous());

		this.rdvForm.patchValue(this.data);

		this.rdvForm.get('patientID').disable();
		this.rdvForm.get('patientName').disable();
	}

	printRdv(value: any) {
		const date = moment(value.date).format('YYYY-MM-DD') + ' ' + this.time;
		value = assign(value, {
			date: date,
			patientID: this.data.patientID,
			accessionNumber: this.data.accessionNumber,
			patientName: this.data.patientName,
		});

		this.service
			.printRdvForResult(value)
			.subscribe(_ => this._dialogRef.close());
	}
}
