import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileExplorerComponent } from "./file-explorer/file-explorer.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  AudioPlayComponent,
  AudioRecordComponent,
  CameraComponent,
  ImageViewerComponent,
  RecordRtcComponent,
  SpeechNoteComponent,
} from "./modals";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatInputModule,
        MatGridListModule,
        FormsModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FileExplorerComponent,
        RecordRtcComponent,
        CameraComponent,
        ImageViewerComponent,
        AudioRecordComponent,
        AudioPlayComponent,
        SpeechNoteComponent,
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())],
    exports: [FileExplorerComponent],
})
export class FileExplorerModule {}
