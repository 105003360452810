import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'ft-form-button',
    templateUrl: './form-button.component.html',
    styleUrls: ['./form-button.component.css'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatButton,
    ],
})
export class FormButtonComponent implements Field {
	config: any;
	group: FormGroup;
}
