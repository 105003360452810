<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-calendar-question"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "SEARCH_AVAILABILITY" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div mat-dialog-content>
  <div class="fx-layout-row-nowrap fx-content-space-between fx-gap-4">
    <div class="fx-layout-column search-side" [formGroup]="form">
      <div [formGroup]="resourceForm" class="fx-layout-column-nowrap">
        <mat-form-field formGroupName="aet">
          <mat-label>{{ "AET" | translate }}</mat-label>
          <mat-select
            [placeholder]="'AET' | translate"
            formControlName="id"
            required
          >
            @for (aet of aets; track $index) {
            <mat-option [value]="aet.id">
              {{ aet.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field formGroupName="technician">
          <mat-label>{{ "STAFF" | translate }}</mat-label>
          <mat-select
            [placeholder]="'TECHNICIAN' | translate"
            formControlName="id"
            (openedChange)="selected = !selected"
          >
            @for (staff of staffList; track $index) {
            <mat-option [value]="staff.id">
              {{ staff.fullName }}
              @if (selected) {
              <span class="list-option-badge">{{ staff.function?.value }}</span>
              }
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="fx-layout-row fx-content-start fx-items-center">
        <mat-form-field>
          <button
            mat-icon-button
            matIconPrefix
            (click)="previousDate(form.value)"
          >
            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-left"></mat-icon>
          </button>
          <mat-label>{{ "START_DATE" | translate }}</mat-label>
          <input
            matInput
            style="padding-left: 4px !important"
            [matDatepicker]="datePicker"
            placeholder="{{ 'START_DATE' | translate }}"
            formControlName="date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <button mat-icon-button matIconSuffix (click)="nextDate(form.value)">
            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>{{ "DURATION" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'DURATION' | translate }}"
          formControlName="duration"
        />
      </mat-form-field>
    </div>

    <div class="mat-table result-side" class="fx-grow-1">
      <mat-table #table [dataSource]="dataSource" class="fire-table" matSort>
        <!--Table-->
        @for (col of displayedColumns; track $index) {
        <ng-container
          cdkColumnDef="{{ col.label }}"
          [class.hidden]="col.hidden"
        >
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            {{ col.header | translate }}
            @if (col.unit !== 'date' && col.unit !== 'color') {
            <span>{{ col.unit }}</span>
            }
          </mat-header-cell>

          @if (col.unit === 'date') {
          <mat-cell *cdkCellDef="let row">
            {{ row[col.label] | date : "dd/MM/yyyy" }}
          </mat-cell>
          } @if (col.unit !== 'date') {
          <mat-cell *cdkCellDef="let row"> {{ row[col.label] }}</mat-cell>
          }
        </ng-container>
        }

        <!--actions-->
        <ng-container cdkColumnDef="action">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row" style="text-align: right">
            @if (!fromCalendar) {
            <button
              color="primary"
              mat-icon-button
              [matTooltip]="'CREATE_ORDER' | translate"
              (click)="createOrder(row)"
            >
              <mat-icon
                style="color: inherit"
                fontIcon="mdi-telegram"
                fontSet="mdi"
              ></mat-icon>
            </button>
            }
          </mat-cell>
        </ng-container>

        <!--header-->
        <mat-header-row *cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
        <mat-row
          *cdkRowDef="let row; columns: cols"
          (click)="onSelect(row)"
        ></mat-row>
      </mat-table>

      <mat-paginator
        class="no-border-bottom"
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 25]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button color="warn" mat-dialog-close mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
</mat-dialog-actions>
