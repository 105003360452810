import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { of as observableOf, Subscription } from 'rxjs';
import {
	ReferringPhysicianAddComponent,
	SharedService,
	ValueDescriptionComponent,
} from '../../shared';
import {
	catchError,
	debounceTime,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { PatientService } from '../../patient/patient.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {
	AppointmentDetailsDTO,
	AppointmentDTO,
	PatientDTO,
	ProcedureCode,
} from '../../model';
import moment from 'moment';
import { SchedulerService } from '../scheduler.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { assign } from 'lodash';
import { ProcedureCodeSearchComponent } from '../procedure-code-search/procedure-code-search.component';
import { PatientListComponent } from '../../shared/patient-list/patient-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-appointment-edit',
    templateUrl: './appointment-edit.component.html',
    styleUrls: ['./appointment-edit.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatSuffix,
        MatInput,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatDatepicker,
        MatRadioGroup,
        MatRadioButton,
        MatDivider,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class AppointmentEditComponent implements OnInit {
	numOfDuplicatePatients: number;
	subs: Subscription[] = [];
	patientSelected: boolean;
	appointmentForm: FormGroup;
	patientForm: FormGroup;
	ageForm: FormGroup;
	minDate = new Date();
	procedureCodeControl: FormControl = new FormControl(
		'',
		Validators.required
	);
	selected: null;
	filteredProcedures: ProcedureCode[] = [];
	referringPhysicianControl: FormControl = new FormControl('');
	filteredReferringPhysicians: any[] = [];

	titles: any[] = [];
	genders: any[] = [];
	confidentialities: any[] = [];
	aets: any[] = [];
	performingPhysicians: any[] = [];
	priorities: any[] = [];
	reasonForExams: any[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private _shared: SharedService,
		private _scheduler: SchedulerService,
		private _dialog: MatDialog,
		private _patientService: PatientService,
		private _dialogRef: MatDialogRef<any>
	) {
		this.setupForms();
	}

	ngOnInit(): void {
		const datasets =
			'titles,genders,confidentialities,aets,rooms,performingPhysicians,priorities,reasonForExams';
		this.subs.push(
			this._shared.getDatasets(datasets).subscribe(data => {
				datasets.split(',').forEach(it => (this[it] = data[it]));

				this.subs.push(
					this.procedureCodeControl.valueChanges
						.pipe(
							startWith(''),
							switchMap(query =>
								this._shared.getPaginatedProcedureCodes(
									10,
									0,
									'code',
									'asc',
									query
								)
							),
							map(data => data['content']),
							catchError(() => observableOf([]))
						)
						.subscribe(data => (this.filteredProcedures = data))
				);
				this.procedureCodeControl.patchValue('');

				this.subs.push(
					this.referringPhysicianControl.valueChanges
						.pipe(
							startWith(''),
							switchMap(query =>
								this._shared.queryReferringPhysicians(
									10,
									0,
									'lastName',
									'asc',
									query
								)
							),
							map(data => data['content']),
							catchError(() => observableOf([]))
						)
						.subscribe(
							data => (this.filteredReferringPhysicians = data)
						)
				);
				this.referringPhysicianControl.patchValue('');

				this.checkExistingPatientsCount();

				if (this.data.patient) this.selectPatient(this.data.patient);
				if (this.data.appointment)
					this.selectAppointment(this.data.appointment);

				if (this.data.selectedDateRange)
					this.appointmentForm.patchValue({
						startDate: this.data.selectedDateRange.start,
						endDate: this.data.selectedDateRange.end,
						startTime: moment(
							this.data.selectedDateRange.start
						).format('HH:mm'),
						endTime: moment(
							this.data.selectedDateRange.start
						).format('HH:mm'),
					});

				if (this.data.resource) {
					const { name, id } = this.data.resource;
					if (name === 'aet') {
						const aet = this['aets'].find(ae => ae.id === id);
						const room = this['rooms'].find(
							_r => _r.name === aet.room
						);
						this.appointmentForm.get('aetId').patchValue(id);
						this.appointmentForm.get('roomId').patchValue(room?.id);
					} else if (name === 'physician') {
						this.appointmentForm.get('physicianId').patchValue(id);
					} else if (name === 'room') {
						const room = this['rooms'].find(_r => _r.id === id);
						const aet = this['aets'].find(
							ae => ae.room === room.name
						);

						this.appointmentForm.get('aetId').patchValue(aet?.id);
						this.appointmentForm.get('roomId').patchValue(id);
					}
				}
			})
		);
	}

	createAppointment() {
		const patient = this.patientForm.getRawValue();
		const appointment = this.appointmentForm.getRawValue();
		const appointmentDetails = new AppointmentDetailsDTO(
			patient,
			appointment
		);
		const saveMethod =
			appointment.id && appointment.id != 0
				? 'updateAppointmentDTO'
				: 'createAppointmentDTO';

		this.subs.push(
			this._scheduler[saveMethod](appointmentDetails).subscribe(
				(isOk: boolean) => this._dialogRef.close(isOk)
			)
		);
	}

	findPatient() {
		const { firstName, lastName } = this.patientForm.getRawValue();

		this.subs.push(
			this._dialog
				.open(PatientListComponent, {
					width: '760px',
					data: { firstName, lastName },
				})
				.afterClosed()
				.subscribe(selectedPatient => {
					if (selectedPatient) this.selectPatient(selectedPatient);
				})
		);
	}

	selectPatient(patient: any) {
		if (patient) {
			this.patientSelected = true;

			this.patientForm.patchValue(patient);

			if (patient.consultingDoctorId)
				this._shared
					.getReferringPhysicianById(patient.consultingDoctorId)
					.subscribe(doctor => {
						if (doctor)
							this.referringPhysicianControl.patchValue(
								doctor.fullName
							);
					});

			this.changeAge();
		}
	}

	upperCase(event: any, field: string) {
		const name = event.target.value;
		this.patientForm.get(field).patchValue(name.toUpperCase());
	}

	capitalize(event1: any, field: string) {
		const name = event1.target.value;
		this.patientForm
			.get(field)
			.patchValue(name.charAt(0).toUpperCase() + name.substring(1));
	}

	addTitle() {
		this._dialog
			.open(ValueDescriptionComponent)
			.afterClosed()
			?.subscribe(title => {
				if (title)
					this._shared.createTitle(title).subscribe(res => {
						this['titles'].push(res);
						this.patientForm.get('title').patchValue(res);
					});
			});
	}

	changeDate() {
		const { years, months } = this.ageForm.getRawValue();

		const dateOfBirth = moment()
			.subtract(years, 'years')
			.subtract(months, 'months');

		this.patientForm.get('dateOfBirth').patchValue(dateOfBirth);
	}

	changeAge(): void {
		const value = moment(
			this.patientForm.get('dateOfBirth').value || moment()
		);

		const diffDuration = moment.duration(moment().diff(value));
		const years = diffDuration.years();
		const months = diffDuration.months();
		const days = diffDuration.days();

		this.ageForm.patchValue({ years, months, days });
	}

	searchProcedureCode() {
		this.subs.push(
			this._dialog
				.open(ProcedureCodeSearchComponent, { minWidth: '600px' })
				.afterClosed()
				.subscribe(procedure => {
					if (procedure) {
						this.procedureCodeControl.patchValue(
							procedure.description
						);
						const aet = this['aets'].find(
							v => v.title === procedure.modality.defaultAETitle
						);
						this.appointmentForm.patchValue({
							appointmentReason: procedure.description,
							appointmentReasonId: procedure.id,
							aetId: aet?.id,
							physicianId: (
								procedure.defaultPerformingPhysician ||
								this['performingPhysicians'][0]
							)?.id,
							roomId: aet?.room?.id,
						});
					}
				})
		);
	}

	onChangeProcedureCode(event: MatAutocompleteSelectedEvent) {
		const reason: ProcedureCode = event.option.value;
		this.procedureCodeControl.patchValue(reason.description);

		const aet = this['aets'].find(
			v => v.title === reason.modality.defaultAETitle
		);
		this.appointmentForm.patchValue({
			appointmentReason: reason.description,
			appointmentReasonId: reason.id,
			aetId: aet?.id,
			physicianId: (
				reason.defaultPerformingPhysician ||
				this['performingPhysicians'][0]
			)?.id,
			roomId: aet?.room?.id,
			examTypeId: reason.reasonForExam?.id,
			examType: reason.reasonForExam?.value,
			modality: reason.modality?.value,
		});
	}

	addReferringPhysician() {
		this.subs.push(
			this._dialog
				.open(ReferringPhysicianAddComponent)
				.afterClosed()
				.subscribe(physician => {
					if (physician) {
						this.filteredReferringPhysicians.push(physician);
						this.appointmentForm
							.get('referringPhysicianId')
							.patchValue(physician?.id);
						this.referringPhysicianControl.patchValue(
							physician.fullName
						);
					}
				})
		);
	}

	onChangeRefPhy(event: MatAutocompleteSelectedEvent) {
		const referringPhysician = event.option.value;
		this.referringPhysicianControl.patchValue(referringPhysician.fullName);
		this.appointmentForm
			.get('referringPhysicianId')
			.patchValue(referringPhysician?.id);
	}

	private checkExistingPatientsCount() {
		this.subs.push(
			this.patientForm.valueChanges
				.pipe(debounceTime(500))
				.subscribe(value => {
					if (value.firstName && value.lastName)
						this.subs.push(
							this._patientService
								.countExistingPatients(
									value.firstName,
									value.lastName
								)
								.subscribe(
									total =>
										(this.numOfDuplicatePatients = total)
								)
						);
				})
		);
	}

	private selectAppointment(appointment: AppointmentDTO) {
		const apt: AppointmentDTO = { ...appointment };
		this.appointmentForm.patchValue(
			assign(apt, {
				startTime: moment(apt.startTime, 'YYYY-MM-DD HH:mm:ss').format(
					'HH:mm'
				),
			})
		);

		if (apt.referringPhysicianId)
			this._shared
				.getReferringPhysicianById(apt.referringPhysicianId)
				.subscribe(doctor => {
					if (doctor)
						this.referringPhysicianControl.patchValue(
							doctor.fullName
						);
				});

		this.procedureCodeControl.patchValue(apt.appointmentReason);

		this.subs.push(
			this._scheduler
				.getPatientDTOById(apt.patientId)
				.subscribe(patient => this.selectPatient(patient))
		);
	}

	private setupForms() {
		this.appointmentForm = this._fb.group(
			assign(new AppointmentDTO(), {
				startDate: [new Date(), Validators.required],
				startTime: [moment().format('HH:mm'), Validators.required],
			})
		);

		this.patientForm = this._fb.group(
			assign(new PatientDTO(), {
				firstName: [
					'',
					Validators.compose([
						Validators.required,
						Validators.minLength(3),
					]),
				],
				lastName: [
					'',
					Validators.compose([
						Validators.required,
						Validators.minLength(3),
					]),
				],
				genderId: [null, Validators.required],
			})
		);

		this.ageForm = this._fb.group({ years: 0, months: 0, days: 0 });
	}
}
