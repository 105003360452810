<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-store"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "ARTICLES" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div class="fx-padding-0" style="max-height: 60vh">
  <div
    class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8"
  >
    <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
      <mat-icon matIconPrefix fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
      <input matInput #filter placeholder="{{ 'SEARCHING' | translate }}" />
    </mat-form-field>
  </div>

  <!-- // _id^code^description^purchase_price^sale_price^purchase_vat^sale_vat^has_serial(0 or 1)^has_lot(0 or 1)       Table-->
  <div class="limiter">
    <div class="table">
      <div class="row header">
        <div class="cell">
          {{ "CODE" | translate }}
        </div>
        <div class="cell">
          {{ "DESIGNATION" | translate }}
        </div>
        <div class="cell">
          {{ "BUYING_PRICE" | translate }}
        </div>
        <div class="cell">
          {{ "SELLING_PRICE" | translate }}
        </div>
        <div class="cell">
          {{ "BUYING_VAT" | translate }}
        </div>
        <div class="cell">
          {{ "SELLING_VAT" | translate }}
        </div>
        <div class="cell"></div>
      </div>

      @for (article of articles; track $index) {
      <div
        (click)="selectArticle(article)"
        [class.row-selected]="selection.isSelected(article)"
        [style.cursor]="'pointer'"
        class="row"
      >
        <div class="cell">{{ article[1] }}</div>
        <div class="cell">{{ article[2] }}</div>
        <div class="cell">{{ article[3] }}</div>
        <div class="cell">{{ article[4] }}</div>
        <div class="cell">{{ article[5] }}</div>
        <div class="cell">{{ article[6] }}</div>
        <div [style.textAlign]="'right'" [style.width.px]="24" class="cell">
          <mat-icon
            [fontIcon]="selection.isSelected(article) ? 'mdi-check' : ''"
            [style.color]="'green'"
            [style.marginRight.px]="8"
            [style.top.px]="0"
            fontSet="mdi"
          ></mat-icon>
        </div>
      </div>
      } @if (!articles.length) {
      <div class="row">
        <div class="no-cell">No article found</div>
      </div>
      }
    </div>
  </div>
</div>

<div matDialogActions align="end">
  <button color="warn" mat-raised-button matDialogClose="">
    {{ "EXIT" | translate }}
  </button>
  <button
    (click)="validateSelection()"
    [disabled]="!selection.hasValue()"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
