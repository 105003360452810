@switch (menuType) { @case ('calendar') {
<button class="calendar-resource-button"
  [matTooltip]="'CALENDAR' | translate"
  mat-button
  [matMenuTriggerFor]="menu"
>
  {{ currentCalendar | translate }}
</button>
} @default {
<button  class="calendar-resource-button"
  [matTooltip]="'FILTERBYRESOURCE' | translate"
  mat-icon-button
  [matMenuTriggerFor]="menu"
>
  <mat-icon
    fontSet="mdi"
    fontIcon="mdi-filter-menu"
  ></mat-icon>
</button>
} }

<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="true">
  @for (item of listItem; track $index) {
  <button mat-menu-item (click)="menuItemSelect.next(item.value)">
    <mat-icon [fontIcon]="item.icon" fontSet="mdi"></mat-icon>
    <span>{{ item.name | translate }}</span>
  </button>
  }
</mat-menu>
