import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { ViewportDirective } from '../viewport.directive';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';

@Component({
    selector: 'ft-print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.scss'],
    standalone: true,
    imports: [
        MatGridList,
        MatGridTile,
        ViewportDirective,
        MatIconButton,
        MatIcon,
    ],
})
export class PrintLayoutComponent implements OnChanges {
	tiles = [];
	rowHeight: string = 'fit';
	cols: number = 1;
	gutterSize: string = '2';

	layouts = [
		'1x1',
		'1x2',
		'1x3',
		'1x4',
		'2x1',
		'2x2',
		'2x3',
		'2x4',
		'3x1',
		'3x2',
		'3x3',
		'3x4',
		'3x5',
		'3x6',
		'4x2',
		'4x3',
		'4x4',
	];

	@Input() printLayout: string = '1x1';
	@Input() imageBackground: string = 'black';
	@Input() selectedImages: any[] = [];

	@Output() layoutChange = new EventEmitter<any>();

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['printLayout']) {
			const layout = changes['printLayout']?.currentValue;

			this.tiles = [];

			if (!layout) return;
			const sp = layout.split('x');
			const rows = parseInt(sp[1], 0);
			this.cols = parseInt(sp[0], 0);
			this.rowHeight = `${297 / rows}mm`;
			for (let i = 0; i < rows * this.cols; i++) {
				this.tiles.push('A' + i);
			}
		}
	}
	toggleSynchronize(image: any) {
		image.synchronized = !image.synchronized;
	}

	changeLayout(e: any) {
		this.layoutChange.emit(e);
	}
}
