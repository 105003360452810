<div class="fx-layout-column-nowrap">
  <div
    class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
    style="padding-bottom: 16px"
  >
    <h3>{{ "SEARCH_PARAGRAPHS" | translate }}</h3>
    <span class="fx-grow-1"></span>
    <button mat-button color="warn" (click)="addNewParagraph()">
      <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle"></mat-icon>
      {{ "ADD_NEW" | translate }}
    </button>
  </div>

  <div
    class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4"
    [formGroup]="searchForm"
  >
    <mat-form-field style="width: 40%">
      <mat-label>{{ "SEARCH" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'SEARCH' | translate }}"
        formControlName="key"
      />
    </mat-form-field>

    <mat-form-field style="width: 20%">
      <mat-label>{{ "CATEGORY" | translate }}</mat-label>
      <mat-select formControlName="procedureType">
        @for (pType of procedureTypes; track $index) {
        <mat-option [value]="pType">
          {{ pType }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 40%">
      <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
      <input
        [formControl]="procedureCodeCtrl"
        [matAutocomplete]="autocode"
        matInput
        placeholder="{{ 'PROCEDURE_CODE' | translate }}"
        type="text"
      />
      <mat-autocomplete
        #autocode="matAutocomplete"
        [autoActiveFirstOption]="true"
      >
        @for (item of filteredProcedureCodes | async; track $index) {
        <mat-option
          (onSelectionChange)="procedureCodeChange(item)"
          [value]="item"
        >
          {{ item }}
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div
    style="overflow: auto; height: calc(100vh - 176px)"
    [@listAnimation]="resultsLength"
  >
    @if (newParagraph) {
    <fieldset class="editing">
      <div class="fx-layout-column" [formGroup]="editForm">
        <mat-form-field>
          <mat-label>{{ "TITLE" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'TITLE' | translate }}"
            formControlName="title"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "CATEGORY" | translate }}</mat-label>
          <mat-select formControlName="procedureType">
            @for (pType of procedureTypes; track $index) {
            <mat-option [value]="pType">
              {{ pType }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
          <input
            [formControl]="procedureCodeCtrls[0]"
            [matAutocomplete]="autocode"
            matInput
            placeholder="{{ 'PROCEDURE_CODE' | translate }}"
            type="text"
          />
          <mat-autocomplete
            #autocode="matAutocomplete"
            [autoActiveFirstOption]="true"
          >
            @for ( item of filteredEditProcedureCodes[0] | async; track $index )
            {
            <mat-option
              (onSelectionChange)="procedureEditCodeChange(0, item)"
              [value]="item"
            >
              {{ item }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "TEXT" | translate }}</mat-label>
          <textarea
            matInput
            placeholder="{{ 'TEXT' | translate }}"
            formControlName="text"
            [rows]="5"
            [style.height]="'auto'"
          ></textarea>
        </mat-form-field>
        <div class="fx-layout-row-nowrap fx-content-space-between fx-gap-4">
          <button
            (click)="saveParagraph(editForm.value)"
            color="primary"
            mat-button
          >
            {{ "SAVE" | translate }}
          </button>
          <button (click)="newParagraph = false" color="warn" mat-button>
            {{ "CANCEL" | translate }}
          </button>
        </div>
      </div>
    </fieldset>
    }

    <div class="search-progress">
      @if (isLoadingResults) {
      <mat-progress-bar [mode]="'query'" color="accent"></mat-progress-bar>
      }
    </div>
    @for (paragraph of filteredParagraphs; track $index) {
    <fieldset [class.editing]="paragraphToEdit === paragraph">
      @if (paragraphToEdit === paragraph) {
      <div class="fx-layout-column" [formGroup]="editForm">
        <mat-form-field>
          <mat-label>{{ "TITLE" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'TITLE' | translate }}"
            formControlName="title"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "CATEGORY" | translate }}</mat-label>
          <mat-select formControlName="procedureType">
            @for (pType of procedureTypes; track $index) {
            <mat-option [value]="pType">
              {{ pType }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
          <input
            [formControl]="procedureCodeCtrls[paragraph.id]"
            [matAutocomplete]="autocode"
            matInput
            placeholder="{{ 'PROCEDURE_CODE' | translate }}"
            type="text"
          />
          <mat-autocomplete
            #autocode="matAutocomplete"
            [autoActiveFirstOption]="true"
          >
            @for ( item of filteredEditProcedureCodes[ paragraph.id ] | async;
            track $index ) {
            <mat-option
              (onSelectionChange)="procedureEditCodeChange(paragraph.id, item)"
              [value]="item"
            >
              {{ item }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "TEXT" | translate }}</mat-label>
          <textarea
            matInput
            placeholder="{{ 'TEXT' | translate }}"
            formControlName="text"
            [rows]="5"
            [style.height]="'auto'"
          ></textarea>
        </mat-form-field>
      </div>
      } @else {
      <legend>
        {{ paragraph.title }}
        <div class="legend-badge">
          {{ paragraph.procedureType }} →
          {{ paragraph.procedureCode }}
        </div>
      </legend>
      <div class="text">{{ paragraph.text }}</div>
      }

      <div class="fx-layout-row-nowrap fx-content-space-between fx-gap-4">
        @if (paragraphToEdit === paragraph) {
        <button
          (click)="saveParagraph(editForm.value)"
          color="primary"
          mat-button
        >
          {{ "SAVE" | translate }}
        </button>
        } @else {
        <button
          (click)="insertParagraph(paragraph)"
          class="btn-insert"
          mat-button
        >
          <mat-icon fontIcon="mdi-arrow-left" fontSet="mdi"></mat-icon>
          {{ "INSERT" | translate }}
        </button>
        <span class="fx-grow-1"></span>
        <button
          [matTooltip]="'EDIT' | translate"
          (click)="editParagraph(paragraph)"
          color="primary"
          mat-icon-button
        >
          <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
        </button>
        <button
          [matTooltip]="'DELETE' | translate"
          (click)="deleteParagraph(paragraph)"
          mat-icon-button
        >
          <mat-icon
            class="delete-btn"
            fontIcon="mdi-delete"
            fontSet="mdi"
          ></mat-icon>
        </button>
        } @if (paragraphToEdit === paragraph) {
        <button (click)="paragraphToEdit = null" color="warn" mat-button>
          {{ "CANCEL" | translate }}
        </button>
        }
      </div>
    </fieldset>
    }
  </div>
</div>
