<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "ADD_APPOINTMENT" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<div [formGroup]="appointmentForm" matDialogContent>
  <div
    [formGroup]="patientForm"
    class="fx-layout-row fx-content-space-between fx-items-start fx-gap-4"
  >
    <mat-form-field tabindex="-1">
      <mat-label>{{ "TITLE" | translate }}</mat-label>
      <mat-select
        [placeholder]="'TITLE' | translate"
        formControlName="titleId"
        tabindex="-1"
      >
        @for (title of titles; track $index) {
        <mat-option [value]="title.id">{{ title.value }}</mat-option>
        }
      </mat-select>
      <button
        (click)="$event.stopImmediatePropagation(); addTitle()"
        mat-icon-button
        matSuffix
        tabindex="-1"
      >
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "LAST_NAME" | translate }}</mat-label>
      <input
        (blur)="upperCase($event, 'lastName')"
        [placeholder]="'LAST_NAME' | translate"
        formControlName="lastName"
        matInput
        required
        type="text"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "FIRST_NAME" | translate }}</mat-label>
      <input
        (blur)="capitalize($event, 'firstName')"
        [placeholder]="'FIRST_NAME' | translate"
        formControlName="firstName"
        matInput
        required
      />
    </mat-form-field>

    @if (!data.patient) {
    <button (click)="findPatient()" mat-icon-button>
      <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
      @if (numOfDuplicatePatients && !patientSelected) {
      <span class="badge">{{ numOfDuplicatePatients }}</span>
      }
    </button>
    }
  </div>

  <div
    [formGroup]="patientForm"
    class="fx-layout-row fx-content-start fx-items-end fx-gap-4"
  >
    <mat-form-field style="width: 180px">
      <mat-label>{{ "DATEOFBIRTH" | translate }}</mat-label>
      <input
        (dateInput)="changeAge()"
        [matDatepicker]="picker"
        [max]="minDate"
        formControlName="dateOfBirth"
        matInput
        placeholder="{{ 'DATEOFBIRTH' | translate }}"
      />
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker (closed)="changeAge()"></mat-datepicker>
    </mat-form-field>

    <div
      [formGroup]="ageForm"
      class="fx-layout-row-nowrap fx-content-start fx-gap-4"
    >
      <mat-form-field style="width: 80px">
        <mat-label>{{ "AGE_YEARS" | translate }}</mat-label>
        <input
          (keyup)="changeDate()"
          formControlName="years"
          matInput
          placeholder="{{ 'AGE_YEARS' | translate }}"
          type="number"
        />
      </mat-form-field>

      <mat-form-field style="width: 80px">
        <mat-label>{{ "AGE_MONTHS" | translate }}</mat-label>
        <input
          (keyup)="changeDate()"
          formControlName="months"
          matInput
          placeholder="{{ 'AGE_MONTHS' | translate }}"
          type="number"
        />
      </mat-form-field>
    </div>

    <div class="fx-layout-column-nowrap relative" style="top: -8px">
      <h5
        [style.color]="patientForm.get('genderId').invalid ? '#cc4040' : ''"
        style="margin: 0 8px"
      >
        {{ "GENDER" | translate }}*
      </h5>

      <mat-radio-group
        formControlName="genderId"
        class="fx-layout-row fx-content-start fx-items-end"
      >
        @for (option of genders; track $index) {
        <mat-radio-button [value]="option.id" class="fx-grow-1" required>
          {{ option.value === "U" ? ("UNKNOWN" | translate) : option.value }}
        </mat-radio-button>
        }
      </mat-radio-group>
    </div>

    <span class="fx-grow-1"></span>

    <div
      class="fx-layout-column fx-content-space-between relative"
      style="top: -8px"
    >
      <h5 style="margin: 0 8px">{{ "CONFIDENTIALITY" | translate }}</h5>

      <mat-radio-group
        formControlName="confidentialityId"
        class="fx-layout-row fx-content-start fx-items-end"
      >
        @for (option of confidentialities; track $index) {
        <mat-radio-button [value]="option.id" class="fx-grow-1">
          {{ option.description }}
        </mat-radio-button>
        }
      </mat-radio-group>
    </div>
  </div>

  <div
    [formGroup]="patientForm"
    class="fx-layout-row fx-content-start fx-items-end fx-gap-4"
  >
    <mat-form-field>
      <mat-label>{{ "CIN" | translate }}</mat-label>
      <input [placeholder]="'CIN' | translate" formControlName="cin" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "PHONE" | translate }}</mat-label>
      <input
        [placeholder]="'PHONE' | translate"
        formControlName="phone"
        matInput
        type="tel"
      />
    </mat-form-field>
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "EMAIL" | translate }}</mat-label>
      <input
        [placeholder]="'EMAIL' | translate"
        formControlName="email"
        matInput
        type="email"
      />
    </mat-form-field>
  </div>

  <mat-divider
    style="border-top: 3px dotted #b0bec5; margin: 12px 20%"
  ></mat-divider>

  <div class="fx-layout-row-nowrap fx-gap-8">
    <mat-form-field class="auto-comp fx-grow-1">
      <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
      <input
        [formControl]="procedureCodeControl"
        [matAutocomplete]="modelList"
        [placeholder]="'PROCEDURE_CODE' | translate"
        matInput
        required
      />
      <mat-autocomplete
        #modelList="matAutocomplete"
        (opened)="selected = null"
        (optionSelected)="onChangeProcedureCode($event)"
        [autoActiveFirstOption]="true"
      >
        @for (pc of filteredProcedures; track $index) {
        <mat-option [value]="pc">
          {{ pc.description }}
        </mat-option>
        }
      </mat-autocomplete>
      <button
        (click)="$event.stopImmediatePropagation(); searchProcedureCode()"
        mat-icon-button
        matSuffix
      >
        <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "REFERRING_PHYSICIAN" | translate }}</mat-label>
      <input
        [formControl]="referringPhysicianControl"
        [matAutocomplete]="refPhyList"
        [placeholder]="'REFERRING_PHYSICIAN' | translate"
        matInput
      />
      <mat-autocomplete
        #refPhyList="matAutocomplete"
        (optionSelected)="onChangeRefPhy($event)"
        [autoActiveFirstOption]="true"
      >
        <ng-container>
          @for (user of filteredReferringPhysicians; track $index) {
          <mat-option [value]="user" class="option-line">
            <div
              [style.fontSize.px]="12"
              [style.lineHeight.px]="14"
              class="fx-layout-column fx-content-center fx-items-start"
            >
              <span class="option-line-1 fx-grow-1">
                {{ user.fullName }}
              </span>
              @if (user.codeAnam) {
              <span class="option-line-2 fx-grow-1">
                Code: {{ user.codeAnam || "-" }}
              </span>
              }
            </div>
          </mat-option>
          }
        </ng-container>
      </mat-autocomplete>
      <button
        (click)="$event.stopImmediatePropagation(); addReferringPhysician()"
        mat-icon-button
        matSuffix
      >
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "EXAMTYPE" | translate }}</mat-label>
      <mat-select
        [placeholder]="'EXAMTYPE' | translate"
        formControlName="examTypeId"
        required
      >
        @for (reason of reasonForExams; track $index) {
        <mat-option [value]="reason.id">{{ reason.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "AET" | translate }}</mat-label>
      <mat-select [placeholder]="'AET' | translate" formControlName="aetId">
        @for (title of aets; track $index) {
        <mat-option [value]="title.id">{{ title.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "PERFORMING_PHYSICIAN" | translate }}</mat-label>
      <mat-select
        [placeholder]="'PERFORMING_PHYSICIAN' | translate"
        formControlName="physicianId"
      >
        @for (item of performingPhysicians; track $index) {
        <mat-option [value]="item.id">{{ item.fullName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
    <mat-form-field class="fx-grow-1">
      <mat-label>{{ "PRIORITY" | translate }}</mat-label>
      <mat-select
        [placeholder]="'PRIORITY' | translate"
        formControlName="priorityId"
      >
        @for (item of priorities; track $index) {
        <mat-option [value]="item.id">{{ item.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 257px">
      <mat-label>{{ "DATE" | translate }}</mat-label>
      <input
        [matDatepicker]="myDatepicker"
        [placeholder]="'DATE' | translate"
        formControlName="startDate"
        matInput
        required
      />
      <mat-datepicker-toggle
        [for]="myDatepicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 150px">
      <mat-label>{{ "TIME" | translate }}</mat-label>
      <input
        [placeholder]="'TIME' | translate"
        formControlName="startTime"
        matInput
        type="time"
        required
      />
    </mat-form-field>

    <mat-form-field style="width: 100px">
      <mat-label>{{ "DURATION" | translate }}</mat-label>
      <input
        [placeholder]="'DURATION' | translate"
        formControlName="duration"
        inputmode="numeric"
        matInput
        type="number"
      />
    </mat-form-field>
  </div>

  <mat-form-field [style.width.%]="100">
    <mat-label>{{ "Autres informations médicales" | translate }}</mat-label>
    <textarea
      [placeholder]="'COMMENTS' | translate"
      formControlName="comments"
      matInput
      name="comments"
    ></textarea>
  </mat-form-field>
</div>
<div [align]="'end'" matDialogActions>
  <button [matDialogClose]="null" color="warn" mat-raised-button>
    {{ "CLOSE" | translate }}
  </button>
  <button
    (click)="createAppointment()"
    [disabled]="procedureCodeControl.invalid || patientForm.invalid"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
