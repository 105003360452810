import {AfterViewInit, Component, DestroyRef, ElementRef, inject, ViewChild} from '@angular/core';
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, fromEvent, merge, of} from 'rxjs';
import {SharedService} from '../../shared';
import { MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
} from 'rxjs/operators';
import {rowsAnimation} from '../../animations';
import {PROCEDURE_CODE_TABLE_CONFIG} from './table-conf';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { TranslateModule } from '@ngx-translate/core';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-procedure-codes',
    templateUrl: './procedure-codes.component.html',
    styleUrls: ['./procedure-codes.component.scss'],
    animations: [rowsAnimation],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        MatFormField,
        MatPrefix,
        MatInput,
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ProcedureCodesComponent implements AfterViewInit {
    cols: any[];
    displayedColumns = [];

    dataSource = new MatTableDataSource();
    @ViewChild('filter', {static: true}) filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    trackById = (index: number, item: any): string => item.id;

    #destroyRef = inject(DestroyRef);

    constructor(
        private sharedService: SharedService,
        public dialogRef: MatDialogRef<ProcedureCodesComponent>
    ) {
        this.displayedColumns = PROCEDURE_CODE_TABLE_CONFIG;
        this.cols = PROCEDURE_CODE_TABLE_CONFIG.map(it => it.label);
    }

    selectCode = row => {
        if (row) this.dialogRef.close(row);
    };

    ngAfterViewInit() {
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(debounceTime(400),
                distinctUntilChanged(),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe(() => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(this.filter.nativeElement.value);
            });

        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        const observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable(),
        ];

        merge(...observedFilters)
            .pipe(
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.sharedService.queryProcedureCodes(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue()
                    );
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];

                    return data['content'];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return of([]);
                }),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe(data => (this.dataSource.data = data));
    }
}
