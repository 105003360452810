import {
	BehaviorSubject,
	forkJoin,
	merge as observableMerge,
	Observable,
} from 'rxjs';

import { map } from 'rxjs/operators';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from '../../shared';
import { AVSC } from '../../model';
import { SchedulerService } from '../scheduler.service';
import { AV_HEADER_COLS, AV_TABLE_CONF } from './table-conf';

import { find as _find, get, map as _map, reduce, set as _set } from 'lodash';
import { DataSource } from '@angular/cdk/collections';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-availability-check',
    templateUrl: './availability-check.component.html',
    styleUrls: ['./availability-check.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatPrefix,
        MatInput,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        MatTooltip,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        MatDialogActions,
        MatButton,
        DatePipe,
        TranslateModule,
    ],
})
export class AvailabilityCheckComponent implements OnInit {
	aets: any[];
	staffList: any[];
	avsc: AVSC;
	resourceForm: FormGroup;
	form: FormGroup;

	cols: any[];
	displayedColumns = [];

	minDate = new Date();
	minTime = moment().format('HH:mm');

	dataSource: TimeSlotDataSource | null;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	selected: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public fromCalendar: boolean,
		private scheduleService: SchedulerService,
		private sharedService: SharedService,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AvailabilityCheckComponent>
	) {
		this.createForm();

		this.resourceForm.valueChanges.subscribe(value => {
			if (this.resourceForm.valid)
				this.findAetAvailabilityTime(this.form.getRawValue());
		});

		this.form.valueChanges.subscribe(value => {
			if (this.resourceForm.valid) this.findAetAvailabilityTime(value);
		});
	}

	onSelect(row) {
		if (this.fromCalendar) this.dialogRef.close(row);
	}

	previousDate(value) {
		let selectedDate = moment(value.date).subtract(1, 'day');
		if (selectedDate.isBefore(moment())) selectedDate = moment();
		const date = selectedDate.format();
		setTimeout(() => this.form.get('date').patchValue(date), 100);
	}

	nextDate(value) {
		const date = moment(value.date).add(1, 'day').format();
		setTimeout(() => this.form.get('date').patchValue(date), 100);
	}

	ngOnInit() {
		this.displayedColumns = AV_TABLE_CONF;
		this.cols = AV_HEADER_COLS;

		forkJoin([
			this.sharedService.getAetList(),
			this.sharedService.getTechnicians(),
		]).subscribe(data => {
			[this.aets, this.staffList] = data;

			if (!this.fromCalendar) {
				_set(this.avsc, 'date', new Date());
				_set(this.avsc, 'duration', 10);
			}

			this.form.patchValue(this.avsc);
			this.resourceForm.patchValue({
				aet: _find(this.aets, { id: this.avsc.aetId }) || { id: '' },
				technician: _find(this.staffList, {
					id: this.avsc.staffId,
				}) || { id: '' },
			});
		});

		this.dataSource = new TimeSlotDataSource(this.paginator, this.sort);
	}

	findAetAvailabilityTime(avsc: AVSC) {
		avsc.aetId = this.resourceForm.get('aet').get('id').value;
		avsc.staffId = this.resourceForm.get('technician').get('id').value;

		avsc.date = new Date(moment(avsc.date).add(1, 'h').format());

		const today = moment(avsc.date, 'YYYY-MM-DD').isSame(moment(), 'day');

		avsc.start = today
			? moment().format('HH:mm')
			: moment().startOf('day').format('HH:mm');
		avsc.end = moment().endOf('day').format('HH:mm');

		this.scheduleService.findAvailabilities(avsc).subscribe(data => {
			const items = _map(data.timeSlots, tsk =>
				reduce(
					this.displayedColumns,
					(obj, field) => {
						if (['aet', 'staff'].indexOf(field.label) === -1)
							obj[field.label] = get(
								tsk,
								field.value,
								field.defaultValue
							);
						else {
							obj['aet'] = data.aet;
							obj['staff'] = data.staff;
						}
						return obj;
					},
					{}
				)
			);

			this.dataSource.dataChange.next(items);
		});
	}

	createOrder(row) {
		this.dialogRef.close(row);
	}

	private createForm(): void {
		this.resourceForm = this.fb.group({
			technician: this.fb.group({ id: '' }),
			aet: this.fb.group({ id: ['', Validators.required] }),
		});

		this.form = this.fb.group({
			date: [new Date(), Validators.required],
			start: this.minTime,
			end: '',
			duration: '10',
			aetId: '',
			staffId: '',
		});

		this.form
			.get('date')
			.valueChanges.subscribe(
				value =>
					(this.minTime = moment(value).isAfter(moment())
						? '08:00'
						: moment().format('HH:mm'))
			);
	}
}

export class TimeSlotDataSource extends DataSource<any> {
	filteredData: AvailabilitySlot[] = [];
	renderedData: AvailabilitySlot[] = [];

	/** Stream that emits whenever the data has been modified. */
	dataChange: BehaviorSubject<AvailabilitySlot[]> = new BehaviorSubject<
		AvailabilitySlot[]
	>([]);

	constructor(
		private _paginator: MatPaginator,
		private _sort: MatSort
	) {
		super();
	}

	get data(): AvailabilitySlot[] {
		return this.dataChange.value;
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<AvailabilitySlot[]> {
		const displayDataChanges = [
			this.dataChange,
			this._paginator.page,
			this._sort.sortChange,
		];

		return observableMerge(...displayDataChanges).pipe(
			map(() => {
				// Filter data
				this.filteredData = this.data
					.slice()
					.filter((item: AvailabilitySlot) => {
						return true;
					});

				// Sort filtered data
				const sortedData = this.sortData(this.filteredData.slice());

				// Grab the page's slice of the filtered sorted data.
				const startIndex =
					this._paginator.pageIndex * this._paginator.pageSize;
				this.renderedData = sortedData.splice(
					startIndex,
					this._paginator.pageSize
				);
				return this.renderedData;
			})
		);
	}

	disconnect() {}

	/** Returns a sorted copy of the database data. */
	sortData(data: AvailabilitySlot[]): AvailabilitySlot[] {
		if (!this._sort.active || this._sort.direction === '') {
			return data;
		}

		return data.sort((a, b) => {
			let propertyA: number | string = '';
			let propertyB: number | string = '';

			switch (this._sort.active) {
				case 'aet':
					[propertyA, propertyB] = [a.aet, b.aet];
					break;
				case 'startTime':
					[propertyA, propertyB] = [a.startTime, b.startTime];
					break;
				case 'date':
					[propertyA, propertyB] = [a.date, b.date];
					break;
				case 'duration':
					[propertyA, propertyB] = [a.duration, b.duration];
					break;
				case 'endTime':
					[propertyA, propertyB] = [a.endTime, b.endTime];
					break;
			}

			const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
			const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

			return (
				(valueA < valueB ? -1 : 1) *
				(this._sort.direction === 'asc' ? 1 : -1)
			);
		});
	}
}

export interface AvailabilitySlot {
	aet: string;
	startTime: string;
	endTime: string;
	date: string;
	duration: string;
}
