import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-delete-confirm',
    templateUrl: './delete-confirm.component.html',
    styleUrls: ['./delete-confirm.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        TranslateModule
    ],
})
export class DeleteConfirmComponent {
	selectedItem: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public message: string,
		private dialogRef: MatDialogRef<DeleteConfirmComponent>
	) {}

	onYes() {
		this.dialogRef.close(true);
	}

	onNo() {
		this.dialogRef.close(false);
	}
}
