import { Component, Inject, OnInit } from '@angular/core';
import { ICONS } from '../../utils';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'ft-icon-select',
    templateUrl: './icon-select.component.html',
    styleUrls: ['./icon-select.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatIcon,
        MatTooltip,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ],
})
export class IconSelectComponent implements OnInit {
	icons = [];
	selectedIcon: string;

	constructor(@Inject(MAT_DIALOG_DATA) public icon: string) {}

	ngOnInit(): void {
		this.icons = ICONS;
	}
}
