<mat-toolbar class="ftx-dialog-toolbar" color="primary">
    <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
    <h3 class="text-lg font-semibold pl-1">
        {{ "AFTER_EXAM" | translate }}
    </h3>

    <span class="fx-grow-1"></span>
    <button mat-icon-button [matDialogClose]="false" tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div matDialogContent>

    <div [formGroup]="form">
        <div>
            <div class="item-header-x">{{ form.get('patientName').value }} </div>
            <div class="item-subheader-x">{{ form.get('patientID').value }}</div>
        </div>


        <!-- Dynamic FormArray for items -->
        <div formArrayName="examCompletionDetailItems">

            @for (item of examCompletionDetailItems.controls; let i = $index; track i) {
                <div [formGroupName]="i" class="flex flex-col p-4 rounded-xl border-gray border border-solid mb-1" style="background: #f2f0f5">

                    <div>
                        <div class="item-header">{{ item.get('procedureCode').value }}
                            <span>({{ item.get('accessionNumber').value }})</span></div>
                        <div class="item-subheader">{{ item.get('studyInstanceUID').value }}</div>
                    </div>

                    <div class="flex flex-row content-between flex-nowrap gap-4 pb-4">

                        @if (eHealthBoxEnabled) {
                            <mat-checkbox formControlName="sharedWithEHealthBox">{{ 'shared.shared_health_box' | translate }}</mat-checkbox>
                        }

                        @if (!eFactEnabled) {
                            <mat-checkbox formControlName="billed">{{ 'BILLED' | translate }}</mat-checkbox>
                        } @else {
                            <mat-checkbox formControlName="billedWithEFact">{{ 'shared.billed_efact' | translate }}</mat-checkbox>
                        }

                        <mat-checkbox formControlName="hasContrast">{{ 'shared.has_contrast' | translate }}</mat-checkbox>

                    </div>

                    <mat-form-field subscriptSizing="dynamic" class="w-full">
                        <mat-label>{{ 'shared.notes' | translate }}</mat-label>
                        <input matInput formControlName="notes">
                    </mat-form-field>
                </div>
            }


        </div>
    </div>

</div>

<div matDialogActions align="end">
    <button [matDialogClose]="false" color="warn" mat-button>
        {{ "CANCEL" | translate }}
    </button>
    <button
            (click)="onSave(form.value)"
            [disabled]="form.invalid"
            color="primary"
            mat-raised-button>
        {{ "SAVE" | translate }}
    </button>
</div>
