import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'ft-form-select',
    templateUrl: './form-select.component.html',
    standalone: true,
    imports: [
        MatFormField,
        FormsModule,
        ReactiveFormsModule,
        MatLabel,
        MatSelect,
        MatOption,
    ],
})
export class FormSelectComponent implements Field {
	config: any;
	group: FormGroup;
}
