import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { SettingService } from '../../setting/setting.service';
import { rowsAnimation } from '../../animations';
import { groupBy, sortBy } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatLine } from '@angular/material/core';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-template-search',
    templateUrl: './template-search.component.html',
    styleUrls: ['./template-search.component.scss'],
    animations: [rowsAnimation],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatNavList,
        MatListItem,
        MatLine,
        MatProgressSpinner,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class TemplateSearchComponent implements AfterViewInit {
	reports: { data: any; key: string }[] = [];

	selected: { data: any; key: string };
	constructor(
		private setting: SettingService,
		private dialogRef: MatDialogRef<TemplateSearchComponent>
	) {}
	ngAfterViewInit(): void {
		this.setting.getTemplateModels().subscribe(data => {
			const reports = groupBy(sortBy(data, 'category'), 'category');
			this.reports = Object.keys(reports).map(key => {
				return { key: key, data: sortBy(reports[key], 'name') };
			});

			this.selected = this.reports[0];
		});
	}
	selectRow = row => {
		this.dialogRef.close(row);
	};
}
