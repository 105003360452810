import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { bitmapToBlob } from '../../../shared-functions';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { MatLine } from '@angular/material/core';
import { MatGridList, MatGridTile, MatGridTileText, MatGridTileFooterCssMatStyler } from '@angular/material/grid-list';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

declare let ImageCapture: any;

@Component({
    selector: 'ft-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        MatTooltip,
        NgClass,
        MatGridList,
        MatGridTile,
        MatGridTileText,
        MatGridTileFooterCssMatStyler,
        MatLine,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class CameraComponent implements AfterViewInit, OnDestroy {
	@ViewChild('video', { static: true })
	public video: ElementRef;

	@ViewChild('canvas', { static: true })
	public canvas: ElementRef;

	public captures: any[];
	public items: Array<Blob>;

	private imageCapture;

	private constraints = {
		video: {
			width: { ideal: 4096 },
			height: { ideal: 2160 },
		},
	};
	private stream: MediaStream;

	public constructor(
		private sanitizer: DomSanitizer,
		private dialogRef: MatDialogRef<CameraComponent>
	) {
		this.captures = [];
		this.items = [];
	}

	public ngAfterViewInit() {
		navigator.mediaDevices
			.getUserMedia(this.constraints)
			.then(mediaStream => {
				this.video.nativeElement.srcObject = mediaStream;

				this.stream = mediaStream;

				const track = mediaStream.getVideoTracks()[0];
				this.imageCapture = new ImageCapture(track);
			})
			.catch(error => console.log(error));
	}

	public capture() {
		this.imageCapture
			.grabFrame()
			.then(bitmapToBlob)
			.then(blob => this.onGetBlob(blob))
			.catch(error => console.log(error));
	}

	public saveCaptures() {
		let files = null;
		if (this.items.length > 0)
			files = this.items.map(
				blob =>
					new File(
						[blob],
						`IMG_${moment().format('YYYYMMDDHHmmss')}.png`,
						{ type: 'image/png' }
					)
			);
		this.dialogRef.close(files);
	}

	public deleteCapture(capture) {
		const idx = this.captures.indexOf(capture);
		this.captures.splice(idx, 1);
		this.items.splice(idx, 1);
	}

	cleanURL(oldURL): SafeUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
	}

	ngOnDestroy(): void {
		this.items = [];
		this.stream.getVideoTracks().forEach(track => track.stop());
	}

	private onGetBlob(blob: Blob) {
		const objectUrl = URL.createObjectURL(blob);

		this.items.push(blob);
		this.captures.push(this.cleanURL(objectUrl));
	}
}
