<mat-toolbar class="ftx-dialog-toolbar" color="primary">
	<h3 class="text-lg font-semibold pl-1">{{ 'CONFIRM' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button [matDialogClose]="false" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div matDialogContent>
	<div class="text-lg font-semibold pb-6">
		{{ message || 'DELETEMESSAGE' | translate }}
	</div>
</div>
<mat-dialog-actions align="end" class="p-3">
	<button (click)="onNo()" color="warn" mat-raised-button tabindex="-1">
		{{ 'NO' | translate }}
	</button>
	<button (click)="onYes()" color="primary" mat-raised-button tabindex="2">
		{{ 'YES' | translate }}
	</button>
</mat-dialog-actions>
