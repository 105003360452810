import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { PHARMACY_URL, WORKFLOW_URL } from '../urls';
import { StockMovement, WorkItemSubtotal } from '../model';
import { tap } from 'rxjs/operators';
import { downloadFile, printBlob } from '../utils';

@Injectable({
	providedIn: 'root',
})
export class WorkflowService {
	private _http = inject(HttpClient);

	getWorkflow(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: string,
		filter: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			filter,
		};
		return this._http.get(`${WORKFLOW_URL}/all`, { params });
	}

	getWorkflowItem(accessionNumber: string): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/getOneByAN`, {
			params: { accessionNumber },
		});
	}

	exportToExcel(query: string): Observable<Blob> {
		const params = { keys: query };
		return this._http.get(`${WORKFLOW_URL}/exportToExcel`, {
			params: params,
			responseType: 'blob',
		});
	}

	hideExam(ids: string): Observable<boolean> {
		const params = { ids };
		return this._http.get<boolean>(`${WORKFLOW_URL}/hideExams`, { params });
	}

	calculateCA(dateRange: string): Observable<any> {
		const params = { dateRange };
		return this._http.get(`${WORKFLOW_URL}/calculateCA`, { params });
	}

	syncWithBilling(accessionNumber: string): Observable<any> {
		const params = { accessionNumber };
		return this._http.get(`${WORKFLOW_URL}/sync-billing`, { params });
	}

	saveStockMovement(entity: StockMovement): Observable<StockMovement> {
		return this._http.post<StockMovement>(
			`${PHARMACY_URL}/saveStockMovement`,
			entity
		);
	}

	getWorkflowItems(dateRange: string): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/json`, {
			params: { dateRange },
		});
	}

	findExamsHistory(patientID: any): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/examsHistory`, {
			params: { patientID },
		});
	}

	openSyngo(studyInstanceUIDs: any): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/syngo`, {
			params: { studyInstanceUIDs },
		});
	}

	calculateSubTotals(filter: string): Observable<WorkItemSubtotal> {
		return this._http.get<WorkItemSubtotal>(
			`${WORKFLOW_URL}/calculate-sub-total`,
			{ params: { filter } }
		);
	}

	openAlma(studyInstanceUIDs: string): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/alma`, {
			params: { studyInstanceUIDs },
		});
	}

	generateTasksList(
		query: string,
		printMode: 'PDF' | 'PRINT' = 'PRINT'
	): Observable<any> {
		return this._http
			.get(`${WORKFLOW_URL}/generateTasksList`, {
				params: { query },
				responseType: 'blob',
			})
			.pipe(tap(printMode === 'PRINT' ? printBlob : downloadFile));
	}

	getStockMovement(_mvtId: any) {
		return this._http.get(`${PHARMACY_URL}/stock-movement/${_mvtId}`);
	}
}
