import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	ViewChild,
} from '@angular/core';
import { SharedService } from '../shared.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-stock-articles',
    templateUrl: './stock-articles.component.html',
    styleUrls: ['./stock-articles.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatPrefix,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class StockArticlesComponent implements AfterViewInit {
	articlesSource: any[] = [];
	articles: any[] = [];
	@ViewChild('filter', { static: true }) filter: ElementRef;
	selection = new SelectionModel<string>(true, []);

	constructor(
		@Inject(MAT_DIALOG_DATA) public storeId: number,
		private shared: SharedService,
		private dialogRef: MatDialogRef<StockArticlesComponent>
	) {
		this.shared
			.getArticles(this.storeId)
			.subscribe(
				res =>
					(this.articles = this.articlesSource =
						res.map(it => it.split('@')))
			);
	}

	ngAfterViewInit(): void {
		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400))
			.subscribe(ev => this.filterTable(ev));
	}

	clearInput() {
		this.filter.nativeElement.value = '';
		this.articles = this.articlesSource;
	}

	selectArticle(article: string) {
		this.selection.isSelected(article)
			? this.selection.deselect(article)
			: this.selection.select(article);
	}

	validateSelection() {
		const selectedArticles = this.selection.selected;
		this.dialogRef.close(selectedArticles);
	}

	private filterTable(ev: any) {
		this.articles = this.articlesSource.filter(it =>
			it.toString().includes(ev.target.value)
		);
	}
}
