<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-table"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "NEW_TABLE_VIEW" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div matDialogContent [formGroup]="form">
  <div class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
    <mat-form-field class="fx-grow-1" subscriptSizing="dynamic">
      <mat-label>{{ "NAME" | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'NAME' | translate"
        formControlName="name"
      />
    </mat-form-field>

    <mat-slide-toggle formControlName="publique">{{
      "PUBLIC" | translate
    }}</mat-slide-toggle>
  </div>

  <div
    cdkDropList
    class="example-list fx-overflow-auto"
    (cdkDropListDropped)="drop($event)"
  >
    @for (col of columns | async; track $index) {
    <mat-checkbox
      class="example-box"
      [value]="col"
      (change)="columnSelectionToggle(col)"
      cdkDrag
      [checked]="col.selected"
      >{{ col.header | translate }}</mat-checkbox
    >
    }
  </div>
</div>

<div matDialogActions align="end" class="fx-gap-4">
  <button color="warn" mat-raised-button matDialogClose="">
    {{ "CLOSE" | translate }}
  </button>
  <button
    (click)="save()"
    [disabled]="!form.get('name').value"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
