<mat-form-field [formGroup]="group" class="full-width">
  <mat-label>{{ config.placeholder }}</mat-label>
  <!--chip list-->
  <mat-chip-grid #chipList [formControlName]="config.name">
    @for (option of _value; track $index) {
    <mat-chip-row [removable]="true" (removed)="remove(option)" class="chip">
      {{ option.value }}
      <mat-icon matChipRemove fontIcon="mdi-close" fontSet="mdi"></mat-icon>
    </mat-chip-row>
    }

    <input
      placeholder="{{ config.name }}"
      [matChipInputFor]="chipList"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
      [matAutocomplete]="auto"
      #chipInput
    />
  </mat-chip-grid>

  <!--autocomplete-->
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="addSelected($event)"
  >
    @for (val of sourceFiltered(); track $index) {
    <mat-option [value]="val">
      <span>{{ val.value }}</span>
    </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
