import { Pipe, PipeTransform } from '@angular/core';
import { sumBy } from 'lodash';

@Pipe({
	name: 'sumWorkItemBy',
	standalone: true,
})
export class SumWorkItemByPipe implements PipeTransform {
	transform(row: any, ...field: any[]): number {
		const [label] = field;
		switch (label) {
			case 'totalAmount':
				return (
					sumBy(row.workflowItems, 'totalAmount') -
					sumBy(row.workflowItems, 'discount')
				);
			case 'globalDiscount':
				return row[label];
			case 'leftAmount':
				if (row['paymentStatus'] === 'EXEMPT') return 0;
				return (
					sumBy(row.workflowItems, 'totalAmount') -
					sumBy(row.workflowItems, 'paidAmount') -
					sumBy(row.workflowItems, 'discount') -
					row['globalDiscount']
				);
		}
		return sumBy(row.workflowItems, label);
	}
}
