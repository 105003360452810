<mat-toolbar class="ftx-dialog-toolbar" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-microphone"></mat-icon>
	<h3 class="text-lg font-semibold pl-1">
		{{ 'AUDIO_RECORDER' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<audio #myAudio controls>Audio stream not available.</audio>

	<div
		class="fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-4">
		<button
			style="width: 36px"
			mat-icon-button
			color="primary"
			(click)="startRecording()"
			[matTooltip]="'RECORD' | translate">
			<mat-icon
				[ngStyle]="{ color: 'red' }"
				fontIcon="mdi-record-rec"
				fontSet="mdi"></mat-icon>
		</button>
		<button
			style="width: 36px"
			mat-icon-button
			color="warn"
			(click)="pauseRecording()"
			[matTooltip]="'PAUSE' | translate">
			<mat-icon fontSet="mdi" fontIcon="mdi-pause"></mat-icon>
		</button>
		<button
			style="width: 36px"
			mat-icon-button
			color="primary"
			(click)="stopRecording()"
			[matTooltip]="'STOP' | translate">
			<mat-icon fontIcon="mdi-stop" fontSet="mdi"></mat-icon>
		</button>
	</div>
</div>

<div matDialogActions align="end">
	<button mat-raised-button color="warn" [mat-dialog-close]="null">
		{{ 'CLOSE' | translate }}
	</button>
	<button mat-raised-button color="primary" (click)="saveFile()">
		{{ 'SAVE' | translate }}
	</button>
</div>
