<div class="opt-group" [formGroup]="group">
  <span class="opt-label"> {{ config.name }}</span>

  <mat-radio-group [formControlName]="config.name">
    @for (option of config.options; track $index) {
    <mat-radio-button [value]="option.value">
      {{ option.value }}
    </mat-radio-button>
    }
  </mat-radio-group>
</div>
