import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { SharedService } from '../shared.service';
import {
	trigger,
	transition,
	style,
	animate,
	query,
	stagger,
} from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportParagraph } from '../../model';
import { deleteItemFromArray } from '../shared-functions';
import { forkJoin, merge, Observable, of as observableOf } from 'rxjs';
import {
	catchError,
	debounceTime,
	map,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import { ReportingService } from '../../reporting/reporting.service';
import { union } from 'lodash';
import { DeleteConfirmComponent } from '../delete-confirm/delete-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';

const listAnimation = trigger('listAnimation', [
	transition('* <=> *', [
		query(
			':enter',
			[
				style({ opacity: 0, height: '0px' }),
				stagger(
					'60ms',
					animate(
						'600ms ease-out',
						style({
							opacity: 1,
							height: '*',
						})
					)
				),
			],
			{ optional: true }
		),
	]),
]);

@Component({
    selector: 'ft-report-paragraph',
    templateUrl: './report-paragraph.component.html',
    styleUrls: ['./report-paragraph.component.scss'],
    animations: [listAnimation],
    standalone: true,
    imports: [
        MatButton,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatProgressBar,
        MatIconButton,
        MatTooltip,
        AsyncPipe,
        TranslateModule,
    ],
})
export class ReportParagraphComponent implements AfterViewInit, OnChanges {
	filteredParagraphs: ReportParagraph[] = [];
	paragraphToEdit: ReportParagraph;
	searchForm: FormGroup;
	editForm: FormGroup;

	newParagraph: boolean;
	procedureTypes: string[] = ['TDM', 'IRM', 'RX', 'ECHO'];
	procedureCodes: string[] = [];
	filteredProcedureCodes: Observable<string[]>;
	filteredEditProcedureCodes: Observable<any>[] = [];

	defaultProcedureCode: string;
	defaultProcedureType: string;

	@Input() procedureType: string;
	@Input() procedureCode: string;

	@Output()
	insertParagraphEvent: EventEmitter<ReportParagraph> =
		new EventEmitter<ReportParagraph>();
	procedureCodeCtrl = new FormControl('');
	procedureCodeCtrls: any = {};
	isLoadingResults: boolean = false;
	resultsLength: number;

	constructor(
		private _shared: SharedService,
		private _service: ReportingService,
		private _fb: FormBuilder,
		private _dialog: MatDialog
	) {
		this.editForm = this._fb.group(new ReportParagraph());
		this.searchForm = this._fb.group({
			key: '',
			procedureCode: '',
			procedureType: '',
		});
	}

	private _filterProcedureCodes(value: any): string[] {
		const filterValue = value ? value.toLowerCase() : '';

		return this.procedureCodes.filter(
			code => code.toLowerCase().indexOf(filterValue) === 0
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		const { procedureCode, procedureType } = changes;
		this.defaultProcedureCode = procedureCode.currentValue;
		this.defaultProcedureType = procedureType.currentValue;

		// this.searchForm.patchValue({procedureType: this.defaultProcedureType});
		// this.procedureCodeCtrl.patchValue(this.defaultProcedureCode);
	}

	ngAfterViewInit(): void {
		forkJoin([
			this._shared.getReasonForExamsValues(),
			this._shared.getProcedureCodesValues(),
		]).subscribe(data => {
			[this.procedureTypes, this.procedureCodes] = data;
			this.procedureTypes = union(['--'], this.procedureTypes);

			this.filteredProcedureCodes =
				this.procedureCodeCtrl.valueChanges.pipe(
					startWith(''),
					map(value => this._filterProcedureCodes(value))
				);
		});

		const observedFilters = [
			this.searchForm.valueChanges,
			this.procedureCodeCtrl.valueChanges,
		];

		merge(...observedFilters)
			.pipe(
				debounceTime(250),
				startWith({}),
				switchMap(() => {
					setTimeout(() => (this.isLoadingResults = true));

					const paragraphSearchKeys = this.searchForm.getRawValue();
					const procedureCode = this.procedureCodeCtrl.value;

					return this._service.searchParagraphs(
						paragraphSearchKeys.key,
						procedureCode,
						paragraphSearchKeys.procedureType,
						5,
						0,
						'title',
						'asc'
					);
				}),
				tap(data => {
					this.isLoadingResults = false;
					this.resultsLength = data['totalElements'];
				}),
				map(data => data['content'] as ReportParagraph[]),
				catchError(() => {
					this.isLoadingResults = false;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.filteredParagraphs = data));
	}

	procedureCodeChange(code: any) {
		this.searchForm.get('procedureCode').patchValue(code);
	}

	procedureEditCodeChange(id: number, code: any) {
		this.procedureCodeCtrls[id || 0].patchValue(code);
	}

	public insertParagraph(paragraph: ReportParagraph): void {
		if (!this.paragraphToEdit) this.insertParagraphEvent.emit(paragraph);
	}

	editParagraph(paragraph: ReportParagraph) {
		this.newParagraph = false;
		this.paragraphToEdit = paragraph;
		this.editForm.patchValue(paragraph);
		this.procedureCodeCtrls[paragraph.id] = new FormControl('');
		this.procedureCodeCtrls[paragraph.id].patchValue(
			paragraph.procedureCode
		);
		this.filteredEditProcedureCodes[paragraph.id] = this.procedureCodeCtrls[
			paragraph.id
		].valueChange.pipe(
			startWith(''),
			map(value => this._filterProcedureCodes(value))
		);
	}

	saveParagraph(paragraph: ReportParagraph) {
		paragraph.procedureCode =
			this.procedureCodeCtrls[paragraph.id || 0].value;
		this.newParagraph = false;
		this.paragraphToEdit = null;

		this._service.saveReportParagraph(paragraph).subscribe(data => {
			if (paragraph.id === null) this.filteredParagraphs.push(data);
			else
				this.filteredParagraphs = this.filteredParagraphs.map(it => {
					if (it.id === paragraph.id) it = data;
					return it;
				});
			this.searchForm.get('key').patchValue('');
		});
	}

	deleteParagraph(paragraph: ReportParagraph) {
		this._dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(res => {
				if (res) {
					if (paragraph.id)
						this._service
							.deleteParagraph(paragraph.id)
							.subscribe(res => {
								if (res)
									deleteItemFromArray(
										this.filteredParagraphs,
										paragraph
									);
							});
					else
						deleteItemFromArray(this.filteredParagraphs, paragraph);
					this.searchForm.get('key').setValue('');
				}
			});
	}

	addNewParagraph() {
		this.newParagraph = true;
		this.paragraphToEdit = null;
		this.editForm.patchValue(
			new ReportParagraph(
				null,
				'',
				this.defaultProcedureType,
				this.defaultProcedureCode
			)
		);
		this.procedureCodeCtrls[0] = new FormControl(this.defaultProcedureCode);
		this.filteredEditProcedureCodes[0] =
			this.procedureCodeCtrls[0].valueChanges.pipe(
				startWith(''),
				map(value => this._filterProcedureCodes(value))
			);
	}
}
