<mat-toolbar color="primary">
  <mat-icon fontIcon="mdi-camera" fontSet="mdi"></mat-icon>&nbsp;
  <span>{{ "CAMERA" | translate }}</span>
  <span class="fx-grow-1"></span>
  <button mat-icon-button mat-dialog-close>
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content style="padding: 0 !important">
  <div id="app1" class="fx-layout-row fx-content-start fx-items-start">
    <div class="fx-grow-1" style="height: 364px; padding: 6px; width: 636px">
      <video #video id="video" height="100%" width="100%" autoplay></video>

      <div class="main">
        <div class="out"></div>
        <div
          class="in"
          [matTooltip]="'TAKE_PICTURE' | translate"
          [matTooltipHideDelay]="3000"
          matTooltipPosition="above"
          (click)="capture()"
        ></div>
      </div>
      <canvas #canvas id="canvas" width="640" height="480"></canvas>
    </div>

    <div
      [ngClass]="{
        'captured-images': true,
        visible: captures.length !== 0
      }"
    >
      <mat-grid-list [gutterSize]="'4'" [cols]="2">
        @for (c of captures; track $index) {
        <mat-grid-tile>
          <img [alt]="'capture'" [src]="c" height="100%" />
          <mat-grid-tile-footer style="height: 26px !important; padding: 0">
            <h3 mat-line class="footer-text">
              {{ "IMG_00" + (captures.indexOf(c) + 1) }}
            </h3>
            <button mat-icon-button (click)="deleteCapture(c)">
              <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
            </button>
          </mat-grid-tile-footer>
        </mat-grid-tile>
        }
      </mat-grid-list>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>
    {{ "CLOSE" | translate }}
  </button>
  <button
    mat-button
    color="primary"
    [disabled]="items.length === 0"
    (click)="saveCaptures()"
  >
    {{ "SAVE" | translate }}
  </button>
</mat-dialog-actions>
