import { of as observableOf } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SettingService } from '../../setting/setting.service';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

function passwordMatcher(c: AbstractControl) {
	return c.get('password').value === '' ||
		c.get('password').value === c.get('confirmPassword').value
		? null
		: { nomatch: true };
}

export function required(min: number, max: number): any {
	return Validators.compose([
		Validators.required,
		Validators.minLength(min),
		Validators.maxLength(max),
	]);
}

@Component({
    selector: 'ft-user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        ColorPickerModule,
        MatDialogActions,
        MatButton,
        AsyncPipe,
        TranslateModule,
    ],
})
export class UserUpdateComponent implements OnInit {
	selectedUser: any;
	form: FormGroup;

	exists = observableOf(false);
	color: string = '#E5E5E5';

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<UserUpdateComponent>,
		private settingService: SettingService
	) {
		this.selectedUser = this.data;
		this.createForm();
	}

	onChangeUserName() {
		this.form
			.get('username')
			.valueChanges.pipe(debounceTime(200))
			.subscribe(value => {
				if (value && this.selectedUser.username !== value)
					this.exists = this.settingService.isUserExists(value);
			});
	}

	onSave(data) {
		Object.keys(data).forEach(k => {
			if (!['password', 'confirmPassword'].includes(k))
				this.selectedUser[k] = data[k];
			else if (data['password'] !== '')
				this.selectedUser['password'] = data['password'];
		});

		this.selectedUser.color = this.color;

		this.settingService.updateUser(this.selectedUser).subscribe(res => {
			if (res) this.dialogRef.close(this.selectedUser);
		});
	}

	ngOnInit() {
		this.onChangeUserName();
		this.form.patchValue(this.selectedUser);

		this.color = this.selectedUser.color;
	}

	private createForm() {
		this.form = this.fb.group(
			{
				clinicalID: '',
				username: ['', required(3, 12)],
				password: '',
				confirmPassword: '',
				firstName: ['', required(3, 64)],
				lastName: ['', required(3, 64)],
			},
			{ validator: passwordMatcher }
		);
	}
}
