import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'lodash';
import moment from 'moment';

@Pipe({
	name: 'groupBy',
	standalone: true,
})
export class GroupByPipe implements PipeTransform {
	transform(array: any[], ...args: any[]): any[] {
		const [groupByProp] = args;

		const gr = groupBy(array, groupByProp);

		const result: { key: any; values: any }[] = [];

		// if key is instanceof Date
		Object.entries(gr).forEach(keyValue => {
			const [key, value] = keyValue;
			const found = result.find(it =>
				moment(it.key).isSame(moment(key), 'd')
			);
			if (found) {
				result[result.indexOf(found)]['values'] = [
					...result[result.indexOf(found)]['values'],
					...(value as any),
				];
			} else result.push({ key: moment(key), values: value });
		});
		return result;
	}
}
