<mat-toolbar class="dialog-toolbar">
  <mat-icon fontSet="mdi" fontIcon="mdi-account"></mat-icon>
  <h3 style="padding-left: 6px">{{ "ENTER_PATIENT" | translate }}</h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content style="padding: 12px">
  <div [formGroup]="form" class="fx-layout-column-nowrap">
    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field style="width: 40%">
        <mat-label>{{ "PATIENT_ID" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'PATIENT_ID' | translate"
          formControlName="patientID"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "PATIENT_NAME" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'PATIENT_NAME' | translate"
          formControlName="patientName"
        />
      </mat-form-field>
    </div>

    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="full-width" style="width: 40%">
        <mat-label>{{ "REASON_FOR_EXAM" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'REASON_FOR_EXAM' | translate"
          formControlName="reasonForExam"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'PROCEDURE_CODE' | translate"
          formControlName="procedureCode"
        />
      </mat-form-field>
    </div>

    <mat-form-field class="full-width">
      <mat-label>{{ "SIDE_EFFECTS" | translate }}</mat-label>
      <mat-select
        [placeholder]="'SIDE_EFFECTS' | translate"
        formControlName="sideEffects"
        multiple
      >
        @for (effect of sideEffectsList; track $index) {
        <mat-option [value]="effect.value">{{ effect.value }}</mat-option>
        }
      </mat-select>
      <button mat-icon-button matSuffix (click)="addAllergy($event)">
        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
      </button>
    </mat-form-field>

    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="full-width" style="width: 40%">
        <mat-label>{{ "PERFORMING_RADIOLOGIST" | translate }}</mat-label>
        <mat-select
          [placeholder]="'PERFORMING_RADIOLOGIST' | translate"
          formControlName="performingPhysician"
        >
          @for (rad of radiologists; track $index) {
          <mat-option [value]="rad.id">{{ rad.fullName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "ASSISTANT_RADIOLOGISTS" | translate }}</mat-label>
        <mat-select
          [placeholder]="'ASSISTANT_RADIOLOGISTS' | translate"
          formControlName="assistantPhysicians"
          multiple
        >
          @for (rad of assistantRadiologists; track $index) {
          <mat-option [value]="rad.fullName">{{ rad.fullName }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>{{ "CLINICAL_INFOS" | translate }}</mat-label>
      <textarea
        matInput
        [placeholder]="'CLINICAL_INFOS' | translate"
        formControlName="clinicalInfos"
      ></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "OBSERVATIONS" | translate }}</mat-label>
      <textarea
        matInput
        [placeholder]="'OBSERVATIONS' | translate"
        formControlName="observation"
      ></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button [mat-dialog-close]="false" color="warn" mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
  <button
    (click)="onSave(form.value)"
    [disabled]="form.invalid"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</mat-dialog-actions>
