<div class="fx-layout-column-nowrap">
	<input type="file" (change)="onFilesAdded()" style="display: none;" multiple #file />

	<mat-toolbar class="file-x-toolbar">
		@if (canNavigateUp) {
			<mat-icon class="pointer" (click)="navigateUp()" fontIcon="mdi-arrow-left" fontSet="mdi"></mat-icon>
		}
		<span class="path">{{ path || ("FILES" | translate) }}</span>

		<span class="fx-grow-1"></span>

		<input type="search" class="search-file" [placeholder]="'SEARCH' | translate" (keyup)="searchFile($event?.target['value'])" />

		<mat-icon class="file-x-icon pointer" [matTooltip]="'UPLOAD_FILE' | translate" (click)="uploadFile()" fontIcon="mdi-cloud-upload" fontSet="mdi"></mat-icon>
		<mat-icon class="file-x-icon pointer" [matTooltip]="'TAKE_SPEECH_NOTE' | translate" (click)="takeSpeechNote()" fontIcon="mdi-note-plus" fontSet="mdi"></mat-icon>
		<mat-icon class="file-x-icon pointer" [matTooltip]="'RECORD_AUDIO_FILE' | translate" (click)="recordAudioFile()" fontIcon="mdi-microphone" fontSet="mdi"></mat-icon>
		<mat-icon class="file-x-icon pointer" [matTooltip]="'TAKE_PICTURE' | translate" (click)="captureImage()" fontIcon="mdi-camera" fontSet="mdi"></mat-icon>
		<mat-icon class="file-x-icon pointer" [matTooltip]="'CREATE_NEW_FOLDER' | translate" (click)="createNewFileElement()" fontIcon="mdi-folder-plus" fontSet="mdi"></mat-icon>
		<mat-icon
			class="file-x-icon pointer"
			[matTooltip]="
        ((gridView | async) === true ? 'LIST_VIEW' : 'THUMBNAIL_VIEW')
          | translate
      "
			(click)="switchView()"
			[fontIcon]="
        (gridView | async) === true ? 'mdi-view-list' : 'mdi-view-grid'
      "
			fontSet="mdi"
		></mat-icon>
	</mat-toolbar>

	<div
		class="fx-grow-1 fx-layout-row fx-content-space-between fx-items-stretch file-container"
		[ngClass]="{
      grid: modalMode,
      hidden: !fileElements || fileElements.length === 0
    }"
	>
		<div
			[ngClass]="{
        'grid-view': (gridView | async) === true,
        modal: modalMode
      }"
			class="fx-grow-1"
		>
			@if (canNavigateUp) {
				<div (click)="navigateUp()" class="list-view">
					<div
						[ngClass]="{
            'fx-layout-column': (gridView | async) === true,
            'fx-layout-row-nowrap': (gridView | async) === false
          }"
						class="fx-content-start fx-items-center fx-gap-4"
					>
						<img
							class="file-or-folder-icon pointer fire-folder"
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/019-folder-2.svg"
							alt=""
						/>
						<div class="file-name">...</div>
					</div>
				</div>
			} @for (element of fileElements; track $index) {
			<div class="file-or-folder list-view" [ngClass]="{'grid-item': modalMode && (gridView | async) === true }" id="{{ element.uuid }}" (click)="openElement(element)">
				<span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{ element: element }" #menuTrigger="matMenuTrigger"> </span>
				<div
					[ngClass]="{'fx-layout-column': (gridView | async) === true,'fx-layout-row-nowrap': (gridView | async) === false}"
					class="fx-content-start fx-items-center fx-gap-4"
					(click)="navigate(element)"
					(contextmenu)="openMenu($event, element, menuTrigger)"
				>
					@if (element.folder) {
						<img
							class="file-or-folder-icon pointer fire-folder"
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/017-folder.svg"
							alt=""
						/>
					} @else { @if (element.fileType === 'WAV') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/wav.svg"
						/>
					} @if (element.fileType === 'PNG') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/011-png-1.svg"
						/>
					} @if (element.fileType === 'HTML') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/006-html.svg"
						/>
					} @if (element.fileType === 'PDF') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/007-pdf-1.svg"
						/>
					} @if (element.fileType === 'ZIP') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/009-zip.svg"
						/>
					} @if (element.fileType === 'MP3') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/010-mp3.svg"
						/>
					} @if (element.fileType === 'TXT') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/016-txt.svg"
						/>
					} @if (element.fileType === 'TORRENT') {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/torrent-file-format.svg"
						/>
					} @if ((element.fileType === 'JPG' || element.fileType === 'JPEG')) {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/008-jpg.svg"
						/>
					} @if ((element.fileType === 'XLS' || element.fileType === 'XLSX' || element.fileType === 'CSV')) {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/004-excel.svg"
						/>
					} @if ((element.fileType === 'PPT' || element.fileType === 'PPTX')) {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/005-powerpoint.svg"
						/>
					} @if ((element.fileType === 'DOC' || element.fileType === 'DOCX')) {
						<img
							class="file-or-folder-icon pointer fire-file"
							alt=""
							[ngClass]="{
              'folder-icon-list': (gridView | async) === false
            }"
							src="../../../../assets/images/svg/003-word.svg"
						/>
					} }

					<div
						(blur)="renameFile($event, element)"
						(keydown)="renameOnEnter($event, element)"
						#elt
						(click)="$event.stopImmediatePropagation()"
						contenteditable
						class="file-name"
						[ngClass]="{
              'file-name-list': (gridView | async) === false
            }"
					>
						{{ element.name }}
					</div>

					<span class="fx-grow-1"></span>

					@if ((gridView | async) === false) {
						<div style="width: 180px;" class="fx-layout-row fx-content-end fx-items-center col-info">
							<div
								class="fx-grow-1 file-name"
								[ngClass]="{
                'file-name-list': (gridView | async) === false
              }"
							>
								{{ element.createdDate || now | date : "dd/MM/yyyy" }}
							</div>
							<div
								class="fx-grow-1 file-name"
								[ngClass]="{
                'file-name-list': (gridView | async) === false
              }"
							>
								{{ element.fileType || "FOLDER" | translate }}
							</div>
							<div
								class="fx-grow-1 file-name"
								[ngClass]="{
                'file-name-list': (gridView | async) === false
              }"
							>
								{{ (element.folder ? "" : getHRFileSizeString(element.size)) || "" }}
							</div>
						</div>
					}
				</div>
			</div>
		}
		</div>
	</div>

	<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
		<ng-template matMenuContent let-element="element">
			<button [disabled]="getFolders(fileElements, element).length === 0" mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{ self: element }">
				<mat-icon fontIcon="mdi-folder-move" fontSet="mdi"></mat-icon>
				<span>{{ "MOVE_TO" | translate }}</span>
			</button>
			@if (!element.folder) {
				<button mat-menu-item (click)="downloadFile(element)">
					<mat-icon fontIcon="mdi-download" fontSet="mdi"></mat-icon>
					<span>{{ "DOWNLOAD" | translate }}</span>
				</button>
			}
			<button mat-menu-item (click)="deleteElement(element)">
				<mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
				<span>{{ "DELETE" | translate }}</span>
			</button>
		</ng-template>
	</mat-menu>

	<mat-menu #moveToMenu="matMenu">
		<ng-template matMenuContent let-self="self">
			@for (element of fileElements; track $index) { @if (element.folder && element.id !== self.id) {
				<button mat-menu-item (click)="moveElement(self, element)">
					{{ element.name }}
				</button>
			} }
		</ng-template>
	</mat-menu>
</div>
