import { Component, Inject, OnInit } from '@angular/core';
import { Technique } from '../../model';
import { SharedService } from '../shared.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-technique-edit',
    templateUrl: './technique-edit.component.html',
    styleUrls: ['./technique-edit.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class TechniqueEditComponent implements OnInit {
	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Technique,
		private fb: FormBuilder,
		private sharedService: SharedService,
		private dialogRef: MatDialogRef<TechniqueEditComponent>
	) {
		this.form = this.fb.group(new Technique());
	}

	ngOnInit(): void {
		this.form.patchValue(this.data || new Technique());
	}

	onSave(value: Technique) {
		this.sharedService.createTechnique(value).subscribe(res => {
			this.dialogRef.close(res);
		});
	}
}
