import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { SharedService } from '../shared.service';
import { SelectionModel } from '@angular/cdk/collections';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Article } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-article-serials',
    templateUrl: './article-serials.component.html',
    styleUrls: ['./article-serials.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatPrefix,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ArticleSerialsComponent implements AfterViewInit {
	serials: string[] = [];
	serialsSource: string[] = [];
	@ViewChild('search', { static: true }) filter: ElementRef;
	selection = new SelectionModel<string>(true, []);
	neededQuantity: number = 1;

	constructor(
		@Inject(MAT_DIALOG_DATA) public article: Article,
		private shared: SharedService,
		private dialogRef: MatDialogRef<ArticleSerialsComponent>
	) {
		this.neededQuantity = this.article.quantity;
		if (this.article.serials)
			this.selection.select(
				...this.article.serials.split(',').filter(it => it.length != 0)
			);
		this.shared
			.getArticleSerials(this.article.externalId, this.article.store)
			.subscribe(data => (this.serials = this.serialsSource = data));
	}

	ngAfterViewInit(): void {
		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400))
			.subscribe(ev => this.filterTable(ev));
	}

	selectSerial(serial: string) {
		this.selection.isSelected(serial)
			? this.selection.deselect(serial)
			: this.selection.selected.length < this.neededQuantity
				? this.selection.select(serial)
				: () => {};
	}

	validateSelection() {
		const selectedSerials = this.selection.selected as string[];
		this.dialogRef.close(selectedSerials);
	}

	clearInput() {
		this.filter.nativeElement.value = '';
		this.serials = this.serialsSource;
	}

	private filterTable(ev: any) {
		this.serials = this.serialsSource.filter(it =>
			it.includes(ev.target.value)
		);
	}
}
