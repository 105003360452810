import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SharedService } from '../shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-viewer-select',
    templateUrl: './viewer-select.component.html',
    styleUrls: ['./viewer-select.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ViewerSelectComponent {
	viewerSelect: FormGroup;
	viewers: any[];

	constructor(
		private service: SharedService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<ViewerSelectComponent>
	) {
		this.service
			.getViewers()
			.subscribe(viewers => (this.viewers = viewers));

		this.viewerSelect = this.fb.group({
			aETitle: ['', Validators.required],
			ipAddress: '',
			port: '',
		});
	}
}
