<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">{{ title | translate }}</h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div matDialogContent class="flex flex-col gap-3">
  <mat-form-field>
    <mat-label>{{ "RADIOLOGIST" | translate }}</mat-label>
    <mat-select [(ngModel)]="assignedRadiologist" [compareWith]="compareFn">
      @for (radiologist of radiologists; track $index) {
      <mat-option [value]="radiologist">
        {{ radiologist.fullName }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (title === 'DELEGATE_TASK') {
  <mat-form-field>
    <mat-label>{{ "MESSAGE" | translate }}</mat-label>
    <textarea
      matInput
      [placeholder]="'CONTENT' | translate"
      [(ngModel)]="comment"
    ></textarea>
  </mat-form-field>
  }
</div>
<div matDialogActions align="end" class="p-3">
  <button color="warn" matDialogClose="" mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
  <button
    (click)="saveRow()"
    [disabled]="!assignedRadiologist"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
