import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from '../shared.service';
import { forkJoin } from 'rxjs';
import { SettingService } from '../../setting/setting.service';
import { assign } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { ReferringPhysicianDTO } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-referring-physician-add',
    templateUrl: './referring-physician-add.component.html',
    styleUrls: ['./referring-physician-add.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ReferringPhysicianAddComponent implements OnInit {
	referringForm: FormGroup;

	titles: any[];
	specialities: any[];
	functions: any[];

	private static prepareDate(data: ReferringPhysicianDTO) {
		return assign(Object.assign({}, data), {
			secondaryAddresses: data.secondaryAddresses
				? JSON.parse(data.secondaryAddresses)
				: null,
		});
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private shared: SharedService,
		private setting: SettingService,
		private dialogRef: MatDialogRef<ReferringPhysicianAddComponent>
	) {
		this.createForm();
	}

	save(data): void {
		data.secondaryAddresses = JSON.stringify(
			this.referringForm.get('secondaryAddresses').getRawValue()
		);

		this.setting
			.saveReferringPhysician(data)
			.subscribe(res => this.dialogRef.close(res));
	}

	ngOnInit() {
		forkJoin([
			this.shared.getTitles(),
			this.shared.getSpecialities(),
			this.shared.getFunctions(),
		]).subscribe(data => {
			[this.titles, this.specialities, this.functions] = data;

			if (this.data) {
				const physicianData =
					ReferringPhysicianAddComponent.prepareDate(this.data);

				this.referringForm.patchValue(physicianData);

				const secondaryAddresses =
					physicianData.secondaryAddresses || [];
				if (secondaryAddresses.length > 0) {
					const addresses = this.referringForm.controls[
						'secondaryAddresses'
					] as FormArray;
					for (let j = 0; j < secondaryAddresses.length; j++) {
						const item = secondaryAddresses[j];
						addresses.at(j).patchValue(item);
						if (j < secondaryAddresses.length - 1)
							this.addAddress();
					}
				}
			}
		});
	}

	private createForm() {
		this.referringForm = this.fb.group(
			assign(new ReferringPhysicianDTO(), {
				firstName: ['', Validators.required],
				lastName: ['', Validators.required],
				secondaryAddresses: this.fb.array([]),
				functionId: 130,
			})
		);

		this.addAddress();
	}

	addAddress() {
		const addresses = this.referringForm.controls[
			'secondaryAddresses'
		] as FormArray;
		addresses.push(
			this.fb.group({
				label: '',
				value: '',
			})
		);
	}

	removeAddress(idx: number) {
		const secondaryAddresses = this.referringForm.controls[
			'secondaryAddresses'
		] as FormArray;
		secondaryAddresses.removeAt(idx);
	}
}
